import { Box, Divider, Stack } from "@mui/material";
import { type FieldsByTypeProps, FieldType } from "@toolflow/shared";
import React from "react";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import JsonSchemaInputField from "./fields/jsonSchemaInputField/JsonSchemaInputField";
import NewSelectField from "./fields/NewSelectField";
import PromptInputWrapper from "./fields/PromptInputWrapper";
import { SelectFieldV1, SelectFieldV2 } from "./fields/selectField";
import InputTypeSelectWrapper from "./fields/selectWithInput/InputTypeSelectWrapper";
import SliderField from "./fields/SliderField";
import TextInputContainer from "./fields/textInputContainer/TextInputContainer";
import styles from "./fieldsByType.module.css";
import { FieldsByTypeContextComponent } from "./FieldsByTypeContext";
import SelectToolsField from "./fields/selectTools/SelectToolsField";

const cx = parsedClassNames.bind(styles);

export default function FieldsByType({
  configs,
  settings,
  id,
  updateField
}: FieldsByTypeProps) {
  // Combined object for components and prop getters
  const fieldTypeConfigs: Record<FieldType, React.ElementType> = {
    [FieldType.PromptInput]: PromptInputWrapper,
    [FieldType.PromptInputV2]: TextInputContainer,
    [FieldType.SelectInputV1]: SelectFieldV1,
    [FieldType.SelectInputV2]: SelectFieldV2,
    [FieldType.CustomSelectInput]: InputTypeSelectWrapper,
    [FieldType.JsonSchemaInput]: JsonSchemaInputField,
    [FieldType.Slider]: SliderField,
    [FieldType.NewSelectInput]: NewSelectField,
    [FieldType.SelectTools]: SelectToolsField
  };

  return (
    <Stack spacing={2} divider={<Divider />}>
      {configs.fields.map((field, index) => {
        const Component = fieldTypeConfigs[field.type];
        if (!Component) return null;
        return (
          <Box key={index} className={cx("box-margin-inline")}>
            <FieldsByTypeContextComponent updateField={updateField}>
              <Component field={field} settings={settings} id={id} />
            </FieldsByTypeContextComponent>
          </Box>
        );
      })}
    </Stack>
  );
}
