import React from "react";
import FullWidthCard from "../../../../utilities/components/cards/FullWidthCard";
import DismissKnowledgeCTAButton from "./DismissKnowledgeCTAButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";

function LearnMoreAboutKnowledge() {
  const ctaDismissed = useSelector(
    (state: RootState) => state.knowledge.ctaDismissed
  );

  if (ctaDismissed) return null;

  return (
    <FullWidthCard
      className="m-t-16px bg-color-off-white"
      title="Knowledge base"
      action={<DismissKnowledgeCTAButton />}
      icon={<>💭</>}
      subHeader="Here you can upload and manage your reusable snippets, from brand voice to product features, ensuring streamlined and consistent content usage across tools."
    />
  );
}

export default LearnMoreAboutKnowledge;
