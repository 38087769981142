import { type CustomToolOutputField } from "@toolflow/shared";
import { useToolbuilderContext } from "../../../../../context/ToolBuilderContext";
import { checkIsIterationChild } from "../../../../../hooks/useIterationHelpers";

export default function useIsIterationChild(
  outputField: CustomToolOutputField
) {
  const { state } = useToolbuilderContext();

  const block = state.currentState.blocks.find(
    (b) => b.data.label === outputField.name
  );
  if (block) {
    const availableFields = state.currentState.availableFields[block.id];
    if (!availableFields) return false;

    const parentBlocks = availableFields
      .map((name) =>
        state.currentState.blocks?.find((b) => b.data.label === name)
      )
      .filter((b) => !!b);

    return checkIsIterationChild(parentBlocks);
  }

  return false;
}
