import type { CustomToolInputFieldTypes } from "@toolflow/shared";
import AddInput from "../../../../../addInput/AddInput";
import DrawerBottom from "../../../drawerBottom/DrawerBottom";
import BackToInputsButton from "../BackToInputsButton";
import { useToolInputFieldsContext } from "../inputBuilders/contexts/ToolInputFieldsContext";
import { DeleteInputButton } from "../inputBuilders/DeleteInputButton";
import BackHeader from "../../utilities/BackHeader";
import InputsDoneButton from "../InputsDoneButton";

const InputDetails = ({ inputId }: { inputId: string }) => {
  const {
    toolInputFields,
    changeInputType,
    changeInputName,
    changeInputDescription
  } = useToolInputFieldsContext();
  const selectedInputField = toolInputFields.find(
    (field) => field.id === inputId
  );
  const selectedInputFieldIndex = toolInputFields.findIndex(
    (field) => field.id === inputId
  );

  if (!selectedInputField) return null;

  const handleChangeInputName = (name: string) => {
    changeInputName(name, selectedInputFieldIndex);
  };

  const handleChangeInputDescription = (description: string) => {
    changeInputDescription(description, selectedInputFieldIndex);
  };

  const handleChangeInputType = (type: CustomToolInputFieldTypes) => {
    changeInputType(type, selectedInputFieldIndex);
  };

  return (
    <DrawerBottom
      mainContent={
        <>
          <BackHeader button={<BackToInputsButton />} />
          <div className="p-16px">
            <AddInput
              newField={selectedInputField}
              setNewField={() => {}}
              changeInputName={handleChangeInputName}
              changeInputDescription={handleChangeInputDescription}
              changeInputType={handleChangeInputType}
            />
          </div>
        </>
      }
      leftSlot={<DeleteInputButton index={selectedInputFieldIndex} />}
      rightSlot={<InputsDoneButton />}
    />
  );
};

export default InputDetails;
