import { Typography } from "@mui/material";
import React, { FC } from "react";
import { toolflowPrimaryColor } from "../../../../globalTheme/muiUtils/appTheme";
import DuplicateHomePageEntityMenuItem from "../../builder/toolBuilder/dropdowns/menuItems/DuplicateHomePageEntityMenuItem";
import { getTimeAgoString } from "../../../../utilities/formatters/time/getTimeAgoString";
import NavigateToBuilderMenuItem from "../NavigateToBuilderMenuItemInHome";
import RemixWrapperIconWithTheme from "../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import MarketplaceBlockTags from "../../../tools/components/modelBlockTags/ModelBlockTags";
import { BoldTableCell, StyledTableCell } from "../layout/TableLayouts";
import { ToolsTableData } from "../utlils/tableTypes";
import EntityRow from "./EntityRow";
import HomePageMenuEntityDropdownMenu from "../../../entities/components/HomePageMenuEntityDropdownMenu";
import { rowWidthDict } from "./rowWidthDict";
import { GrowthIcon } from "../../../../globalTheme/icons/icons";
import { useGetCurrentUserProfileId } from "../../../user/hooks/useGetCurrentUserProfileId";
import { type TMarketplaceUniversalEntity } from "@toolflow/shared";
import CopyEntityLink from "../../../tools/components/searchToolsContainer/CopyEntityLink";

const SecondaryAction = ({
  entity
}: {
  entity: TMarketplaceUniversalEntity;
}) => {
  const profileId = useGetCurrentUserProfileId();
  return (
    <>
      {entity.userId === profileId ? (
        <NavigateToBuilderMenuItem entityId={entity.id} type={entity.type} />
      ) : (
        <DuplicateHomePageEntityMenuItem
          id={entity.id}
          type={entity.type}
          iconOnly={true}
        />
      )}
    </>
  );
};

export const OwnerActions = ({
  entity
}: {
  entity: TMarketplaceUniversalEntity;
}) => {
  return (
    <>
      <CopyEntityLink entityId={entity.id} entityType={entity.type} />
      <SecondaryAction entity={entity} />
      <HomePageMenuEntityDropdownMenu homPageEntity={entity} />
    </>
  );
};

interface IEntityTableRowProps {
  tableRow: ToolsTableData;
  removeLastRowBorder?: boolean;
}
const EntityTableRow: FC<IEntityTableRowProps> = ({
  tableRow,
  removeLastRowBorder
}) => {
  const { entity } = tableRow;
  const blocks = tableRow?.blocks || [];
  return (
    <EntityRow
      key={entity.id}
      entityId={entity.id}
      entityType={entity.type}
      removeLastRowBorder={removeLastRowBorder}
    >
      <BoldTableCell sx={{ width: rowWidthDict.name }}>
        {entity.name}
      </BoldTableCell>
      <StyledTableCell sx={{ width: rowWidthDict.updatedAt }}>
        {getTimeAgoString(entity.updatedAt)}
      </StyledTableCell>
      <StyledTableCell sx={{ width: rowWidthDict.useCases }}>
        <div className="flex align-i-center">
          <div className="m-t-4px m-r-8px">
            {!!tableRow.useCases?.length && (
              <RemixWrapperIconWithTheme
                Icon={GrowthIcon}
                size={18}
                providedColor={toolflowPrimaryColor}
              />
            )}
          </div>
          <div>
            {tableRow.useCases?.slice(0, 2).map((useCase, index, array) => (
              <Typography
                variant="body2"
                key={useCase}
                className="capitalize"
                sx={{ display: "inline-block" }}
              >
                {useCase}
                {index !== array.length - 1 ? "/" : null}
              </Typography>
            ))}
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell sx={{ width: rowWidthDict.blocks }}>
        <MarketplaceBlockTags
          blockLabels={blocks}
          maxBlocks={3}
          iconOnly={true}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <OwnerActions entity={entity} />
      </StyledTableCell>
    </EntityRow>
  );
};

export default EntityTableRow;
