import { createFilterOptions, type FilterOptionsState } from "@mui/material";
import type { MarketplaceEntityAutocomplete } from "@toolflow/shared";

const filter = createFilterOptions<MarketplaceEntityAutocomplete>();

const getFilterToolOptions = (
  options: MarketplaceEntityAutocomplete[],
  params: FilterOptionsState<MarketplaceEntityAutocomplete>
) => {
  const filtered = filter(options, params);

  const filteredBasedOnUseCases = options.filter((option) =>
    option?.useCases?.join(" ").includes(params.inputValue)
  );
  const uniqueFiltered = Array.from(
    new Set([...filtered, ...filteredBasedOnUseCases])
  ).sort((a, b) => {
    const groupOrder = { Tools: 1, Workflows: 2, "Use Cases": 3 };
    return (
      (groupOrder[a.group] || Infinity) - (groupOrder[b.group] || Infinity)
    );
  });
  return uniqueFiltered;
};

export default getFilterToolOptions;
