import { DeleteIcon } from "../../../../../../../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../../../../../../../utilities/functions/parsedClassNames";
import { useToolContext } from "../../../../../../../../tools/contexts/ToolContext";
import ToolContextComponentContainer from "../../../../../../../../tools/contexts/ToolContextContainer";
import styles from "./toolListComponent.module.css";

const cx = parsedClassNames.bind(styles);

interface InputListInnerProps {
  toolIds: string[];
  disableHelperText?: boolean;
  placeholder?: string;
  handleChange: (toolIds: string[]) => void;
}

const ToolListItem = ({
  deleteToolId,
  idx
}: {
  deleteToolId: (idx: number) => void;
  idx: number;
}) => {
  const { tool } = useToolContext();
  return (
    <>
      {tool.name}
      <div
        onClick={() => {
          deleteToolId(idx);
        }}
        className={cx(
          "tag-delete-button",
          "border-radius-6px flex align-i-center justify-center"
        )}
      >
        <DeleteIcon size={14} />
      </div>
    </>
  );
};

export default function ToolListComponent({
  toolIds,
  handleChange
}: InputListInnerProps) {
  const deleteToolId = (idx: number) => {
    const filteredTags = toolIds.filter((_, i) => i !== idx);
    handleChange(filteredTags);
  };
  if (!toolIds.length) return null;

  return (
    <div className={cx("tags-list-container", "m-t-16px")}>
      {toolIds.map((toolId, idx) => {
        return (
          <div key={toolId} className={cx("tags-list-item")}>
            <ToolContextComponentContainer toolId={toolId}>
              <ToolListItem deleteToolId={deleteToolId} idx={idx} />
            </ToolContextComponentContainer>
          </div>
        );
      })}
    </div>
  );
}
