import React from "react";
import { SearchIcon } from "../../../../../../../globalTheme/icons/icons";
import useFindToolButton from "./hooks/useFindToolButton";
import TextButtonGray from "./textButtonGray/textButtonGray";
import { SearchToolsDialog } from "../../../../../../tools/components/searchToolsDialog/SearchToolsDialog";

function FindToolButton({ className = "" }: { className?: string }) {
  const { selectTool } = useFindToolButton();
  return (
    <SearchToolsDialog
      onClickEntity={selectTool}
      title="Find tool"
      button={
        <TextButtonGray
          onClick={() => {}}
          className={className}
          text="Find tool"
          Icon={SearchIcon}
        />
      }
    />
  );
}

export default FindToolButton;
