import { FormControl, MenuItem, Select } from "@mui/material";
import { DynamicFieldType, type SelectFieldV2Props } from "@toolflow/shared";
import { isString } from "lodash";
import InputListComponent from "../../../../../../../../tools/components/inputs/InputListComponent";
import useFieldsByTypeHelper from "../../../useFieldsByTypeHelper";
import DynamicFieldWrapper from "../../dynamicFieldWrapper/DynamicFieldWrapper";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";

const FIELD_TYPE_KEY = "fieldType";

export function SelectFieldV2({ field, settings }: SelectFieldV2Props) {
  const { label, subtitle } = field;
  const { fieldKey, options } = field.config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });
  const { updateField } = useFieldsByTypeContext();

  const dynamicFieldValue = getFieldValue(FIELD_TYPE_KEY, "");
  const fieldValue = getFieldValue(fieldKey, "");

  return (
    <FormControl margin="normal" fullWidth>
      <DynamicFieldWrapper
        value={dynamicFieldValue}
        label={label}
        subtitle={subtitle}
        onChange={(value) => {
          updateField(value, `settings.${FIELD_TYPE_KEY}`);

          if (value === DynamicFieldType.Dynamic) {
            updateField("", `settings.${fieldKey}`);
          } else {
            updateField([], `settings.${fieldKey}`);
          }
        }}
      >
        {dynamicFieldValue === DynamicFieldType.Dynamic ? (
          <Select
            id={fieldKey}
            value={fieldValue}
            size="small"
            fullWidth
            onChange={(event) =>
              updateField(event.target.value, `settings.${fieldKey}`)
            }
            className="capitalize nowheel nodrag nopan m-t-16px"
          >
            {options.map((option) => {
              if (isString(option)) {
                return (
                  <MenuItem value={option} key={option} className="capitalize">
                    {option}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    value={option.value}
                    key={option.value}
                    className="capitalize"
                  >
                    {option.label}
                  </MenuItem>
                );
              }
            })}
          </Select>
        ) : (
          <div className="nowheel nodrag nopan m-t-16px">
            <InputListComponent
              valuesList={fieldValue}
              handleChange={(value) =>
                updateField(value, `settings.${fieldKey}`)
              }
            />
          </div>
        )}
      </DynamicFieldWrapper>
    </FormControl>
  );
}
