import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import React from "react";
import styles from "./categoryTabs.module.css";
import useEntityCategory from "./hooks/useCategoryTabs";

const CategoryTabs = () => {
  const {
    handleChangeTabIndex,
    selectedTabIndex,
    marketplaceCategoryTabs: tabs
  } = useEntityCategory();

  return (
    <Box
      sx={{
        flexGrow: 1
      }}
    >
      <Tabs
        value={selectedTabIndex}
        onChange={handleChangeTabIndex}
        variant="scrollable"
        scrollButtons={"auto"}
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 }
          }
        }}
        allowScrollButtonsMobile={true}
      >
        {tabs.map((tab, index) => {
          const isTabSelected = selectedTabIndex === index;
          return (
            <Tab
              key={tab.value}
              icon={isTabSelected ? tab.selectedIcon : tab.icon}
              label={
                <span
                  className={`capitalize ${
                    isTabSelected ? styles.selected : ""
                  }`}
                >
                  {tab.label}
                </span>
              }
            />
          );
        })}
      </Tabs>
      <Divider light={true} />
    </Box>
  );
};

export default CategoryTabs;
