import useGetToolSelectorOptions from "./useGetToolSelectorOptions";
import useGetWorkflowSelectorOptions from "./useGetWorkflowSelectorOptions";

const useGetAllEntityOptions = ({
  disableWorkflowsOptions,
  disableToolsOptions
}: {
  disableWorkflowsOptions?: boolean;
  disableToolsOptions?: boolean;
}) => {
  const toolOptions = useGetToolSelectorOptions();
  const workflowOptions = useGetWorkflowSelectorOptions();

  if (disableWorkflowsOptions) {
    return toolOptions;
  }
  if (disableToolsOptions) {
    return workflowOptions;
  }

  return [...toolOptions, ...workflowOptions];
};

export default useGetAllEntityOptions;
