import type { PromptInputWrapperProps } from "@toolflow/shared";
import useFieldsByTypeHelper from "../useFieldsByTypeHelper";
import PromptEditor from "../../../promptEditor/PromptEditor";
import InputLabelWrapper from "./helpers/InputLabelWrapper";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";

export default function PromptInputWrapper({
  id,
  settings,
  field
}: PromptInputWrapperProps) {
  const { updateField } = useFieldsByTypeContext();
  const { label, subtitle } = field;
  const { prependKey, fieldKey, minRows, maxRows } = field.config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });

  return (
    <InputLabelWrapper label={label} subtitle={subtitle}>
      <PromptEditor
        id={id}
        prompt={getFieldValue(fieldKey, "")}
        maxRows={maxRows}
        minRows={minRows}
        handleUpdatePrompt={(newValue) => {
          const key = prependKey ? [prependKey, fieldKey].join(".") : fieldKey;
          updateField(newValue, key);
        }}
      />
    </InputLabelWrapper>
  );
}
