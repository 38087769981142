import convertAgentFEToMarketplaceEntity from "./convertAgentFEToMarketplaceEntity";
import { useGetCurrentUserAgents } from "./useGetCurrentUserAgents";
import { useMemo } from "react";

export const useGetAllUserAgentsAsEntities = () => {
  const { agents, loading } = useGetCurrentUserAgents();
  const entities = useMemo(() => {
    return agents.map(convertAgentFEToMarketplaceEntity);
  }, [agents]);
  return {
    entities,
    loading
  };
};

export const useOnlyUserAgents = () => {
  const { entities } = useGetAllUserAgentsAsEntities();
  return useMemo(() => entities, [entities]);
};
