import { ChipNodeComponents, type DallEOutputType } from "@toolflow/shared";
import type { Content } from "@tiptap/core";

export function getSearchResponseNode(toolIds: string[]) {
  return {
    type: ChipNodeComponents.SearchToolResponseComponent,
    attrs: {
      toolIds
    }
  };
}

export function getInputNodes(inputId: string) {
  return [
    {
      type: ChipNodeComponents.AssetComponent,
      attrs: {
        inputId
      }
    },
    {
      type: "text",
      text: " "
    }
  ];
}

export function getBlockOutputNodes(name: string) {
  return [
    {
      type: ChipNodeComponents.NodeOutputViewerComponent,
      attrs: {
        name
      }
    },
    {
      type: "text",
      text: " "
    }
  ];
}
export function getToolbuilderInputNodes(name: string) {
  return [
    {
      type: ChipNodeComponents.ToolbuilderInputComponent,
      attrs: {
        name
      }
    },
    {
      type: "text",
      text: " "
    }
  ];
}

export function getSnippetNodes(snippetId: string) {
  return [
    {
      type: ChipNodeComponents.SnippetChipComponent,
      attrs: {
        snippetId
      }
    },
    {
      type: "text",
      text: " "
    }
  ];
}

export function getImageNodes(images: DallEOutputType) {
  // TODO fix this
  const base: Content = images.map((i) => {
    return {
      type: "image",
      attrs: {
        src: i.url,
        aspectRatio: 1,
        alt: null,
        textAlign: "left",
        title: null,
        width: 300
      }
    };
  });
  return base;
}
