import { InputType, type ScraperBlockSettings } from "@toolflow/shared";

export const scraperSettingsInitialState: {
  settings: ScraperBlockSettings;
} = {
  settings: {
    inputType: InputType.FixedInput,
    urlFieldInputKey: "",
    preferredScraperResultType: "markdown"
  }
};
