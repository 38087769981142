import React from "react";
import LinkTextButton from "../../utilities/components/buttons/LinkTextButton";

function TermsOfService({
  typographyClassName = ""
}: {
  typographyClassName?: string;
}) {
  return (
    <LinkTextButton
      to="https://sour-track-509.notion.site/Terms-of-Service-19651bf73cc842839e27901ed72421db"
      text="Terms"
      typographyClassName={typographyClassName}
    />
  );
}

export default TermsOfService;
