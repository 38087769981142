import type { TMessageByThreadId, TTransformedMessage } from "@toolflow/shared";
import { messageTypeDict } from "../messageTypeDict";
import { getMessageType } from "./getMessageType";

const getTransformedMessage = (
  message: TMessageByThreadId
): TTransformedMessage => {
  const messageType = getMessageType(); // we can have different message types for different tools
  const messageString = message.message;
  const messageContent = messageTypeDict[messageType](messageString);
  return {
    role: message.role,
    content: messageContent,
    id: message.id
  };
};

export const getTransformedMessages = (
  messages: TMessageByThreadId[]
): TTransformedMessage[] => {
  return messages.map((message) => getTransformedMessage(message));
};
