import type { JSONContent } from "@tiptap/core";
import { TextInput } from "../../../common/fieldsByType/fields/textInputContainer/TextInputContainer";
import type { UserInputDictType } from "@toolflow/shared";

function TestFieldContainer({
  value,
  onChange,
  label,
  className
}: {
  value: string;
  onChange: (value: string | JSONContent) => void;
  label: string;
  className?: string;
}) {
  return (
    <div className={className}>
      <TextInput
        handleTipTapChange={onChange}
        initialValue={value}
        label={label}
      />
    </div>
  );
}

function TestInputTab({
  fields,
  uI,
  handleUpdate
}: {
  fields: string[];
  uI: UserInputDictType;
  handleUpdate: (field: string, e: string | JSONContent) => void;
}) {
  return (
    <div className="w-100-percent flex flex-column">
      {fields.map((field, idx) => (
        <TestFieldContainer
          label={field}
          key={idx}
          className={idx !== 0 ? "m-t-16px" : undefined}
          value={uI[field] as string}
          onChange={(e) => handleUpdate(field, e)}
        />
      ))}
    </div>
  );
}
export default TestInputTab;
