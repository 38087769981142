import React from "react";
import SavedWorkstationDrawer from "./SavedWorkstationDrawer";
import GetStarted from "./getStarted/GetStarted";
import { useGetStarted } from "./getStarted/hooks/useGetStarted";

function WorkstationSaveSplitter() {
  const getStarted = useGetStarted();
  if (getStarted) {
    return <GetStarted />;
  } else return <SavedWorkstationDrawer />;
}

export default WorkstationSaveSplitter;
