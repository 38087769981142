import {
  EntityType,
  type ValidateTypeWithAgent,
  type TWorkspacePartial,
  AGENT
} from "@toolflow/shared";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../../../../stores/store";
import { useGetToolVersionResponseQuery } from "../../../../ToolflowAPI/rtkRoutes/toolVersionResponseApi";
import { useAuthStore } from "../../../auth/hooks/useAuthStore";
import { useTVRAssetObject } from "../assets/useTVRAssetObject";
import {
  CHAT_TAB_BASE,
  DEFAULT_WORKSPACE,
  TOOL_TAB_BASE,
  WORKFLOW_USE_BASE,
  WORKSPACE_ASSETS_FIELD_LABEL,
  WORKSPACE_ENTITY_BASE,
  WORKSPACE_VISIBLE_ASSET_ID
} from "../helpers/workspaceConstants";
import { clearInitialObject } from "../state/workspaceSlice";

const useGetToolTab = ({ skip, id }: { skip?: boolean; id: string }) => {
  const componentId = useMemo(() => uuidv4(), [id]);
  if (!skip) {
    return {
      [WORKSPACE_ENTITY_BASE]: {
        entityId: id,
        componentId,
        entityType: EntityType.TOOL
      },
      [TOOL_TAB_BASE]: {
        userInput: {}
      }
    };
  }
  return undefined;
};

const useGetAgentTab = ({ skip, id }: { skip?: boolean; id: string }) => {
  if (!skip) {
    return {
      [CHAT_TAB_BASE]: {
        agentId: id,
        currentThreadId: ""
      }
    };
  }
  return undefined;
};

const useGetTVRTab = ({ skip, id }: { skip?: boolean; id: string }) => {
  const { data, isLoading } = useGetToolVersionResponseQuery(id, skip || !id);

  // Compute input objects with memoized IDs only when data changes
  const inputObject = useTVRAssetObject(data);
  return useMemo(() => {
    if (data?.toolVersionResponse) {
      return {
        [WORKSPACE_ASSETS_FIELD_LABEL]: inputObject,
        [WORKSPACE_VISIBLE_ASSET_ID]:
          inputObject.length > 0 ? inputObject[0].id : undefined
      };
    }
    return undefined;
  }, [isLoading, inputObject]);
};

const useGetWorkflow = ({ skip, id }: { skip?: boolean; id: string }) => {
  const componentId = useMemo(() => uuidv4(), [id]);
  if (!skip) {
    return {
      [WORKSPACE_ENTITY_BASE]: {
        entityId: id,
        componentId,
        entityType: EntityType.WORKFLOW
      },
      [WORKFLOW_USE_BASE]: {
        activeStep: 0,
        workflowProgress: {}
      }
    };
  }
  return undefined;
};

const useSetDefaultObject = () => {
  const defaultObject = useSelector(
    (state: RootState) => state.workspace.initialObject
  );
  const { isLoading, isAuthenticated } = useAuthStore();

  const tvr = useGetTVRTab({
    skip: defaultObject?.type !== "toolVersionResponse",
    id: defaultObject?.id || ""
  });

  const agent = useGetAgentTab({
    skip: defaultObject?.type !== AGENT,
    id: defaultObject?.id || ""
  });

  const tool = useGetToolTab({
    skip: defaultObject?.type !== EntityType.TOOL,
    id: defaultObject?.id || ""
  });

  const workflow = useGetWorkflow({
    skip: defaultObject?.type !== EntityType.WORKFLOW,
    id: defaultObject?.id || ""
  });

  return useMemo(() => {
    return {
      object: tvr || tool || workflow || agent || undefined,
      type: defaultObject?.type
    };
  }, [tvr, tool, workflow, isLoading, isAuthenticated, agent]);
};

const useDefaultWorkspaceFormValues = () => {
  const { object, type } = useSetDefaultObject();

  const dispatch = useDispatch();

  const [parsedObject, setParsedObject] = useState<TWorkspacePartial>(
    object ?? {}
  );
  const [parsedType, setParsedType] = useState<
    ValidateTypeWithAgent | undefined
  >(type ?? undefined);

  useEffect(() => {
    if (object) {
      dispatch(clearInitialObject());
      setParsedObject({
        ...DEFAULT_WORKSPACE,
        ...object
      });
      setParsedType(type);
    }
  }, [JSON.stringify(object)]);

  return {
    parsedObject,
    type: parsedType
  };
};

export default useDefaultWorkspaceFormValues;
