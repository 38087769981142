import React from "react";

import {
  useGetToolQuery,
  useUpdateToolMutation
} from "../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import { v4 } from "uuid";
import { EntityType, type TUseCase } from "@toolflow/shared";
import AssignUseCases from "./AssignUseCases";

const AssignUseCasesToolContainer = ({
  toolId,
  className
}: {
  toolId: string;
  className?: string;
}) => {
  const { data, isLoading } = useGetToolQuery(toolId, {
    refetchOnMountOrArgChange: true,
    skip: !toolId
  });
  const [updateTool] = useUpdateToolMutation();
  const fetchedUseCasesTags =
    data?.tool?.tag?.useCases?.map((category) => ({
      id: v4(),
      selected: true,
      value: category
    })) || [];

  const handleSaveTool = (useCases: TUseCase[]) => {
    updateTool({
      toolId,
      body: { tag: { useCases } }
    });
  };

  return (
    <AssignUseCases
      disabled={isLoading}
      handleSave={handleSaveTool}
      fetchedUseCasesTags={fetchedUseCasesTags}
      type={EntityType.TOOL}
      className={className}
    />
  );
};

export default AssignUseCasesToolContainer;
