import type { TMarketplaceEntity } from "@toolflow/shared";
import useGetAvailableTools from "../../../../../features/tools/hooks/useGetAvailableTools";

const useGetToolSelectorOptions = () => {
  const availableTools = useGetAvailableTools();

  const options: Array<TMarketplaceEntity> = availableTools.filter(
    (
      (seenIds) => (item: TMarketplaceEntity) =>
        !seenIds.has(item.id) && seenIds.add(item.id)
    )(new Set<string>())
  );
  return options;
};

export default useGetToolSelectorOptions;
