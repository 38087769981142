import React from "react";
import AuthenticationSplitter from "../../../auth/AuthenticationSplitter";
import AuthenticatedActions from "./authenticatedActions/AuthenticatedActions";
import CreateAFreeAccount from "../../../auth/CreateAFreeAccountButton";
import { classNames } from "../../../../utilities/functions/parsedClassNames";
import { useToolPadding } from "../../../entities/components/entityInfo/useToolPadding";

const EditorToolCardActions = () => {
  const padding = useToolPadding();

  return (
    <div className={classNames(padding)}>
      <AuthenticationSplitter
        authenticatedComponent={<AuthenticatedActions />}
        externalComponent={
          <CreateAFreeAccount variant="contained" className="m-l-auto" />
        }
      />
    </div>
  );
};

export default EditorToolCardActions;
