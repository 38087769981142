import React from "react";
import SnippetFormProvider from "./SnippetFormProvider";
import { Card } from "@mui/material";
import SnippetNameTextField from "./SnippetNameTextField";
import SnippetValueTextField from "./SnippetValueTextField";
import SnippetField from "./SnippetField";
import SaveOrUpdateSnippetButton from "./Buttons/SaveOrUpdateSnippetButton";

const SnippetFormCard = ({
  initialName,
  initialValue,
  className = "",
  initialStateIsDirty
}: {
  initialName?: string;
  initialValue?: string;
  className?: string;
  initialStateIsDirty?: boolean;
}) => {
  return (
    <SnippetFormProvider
      initialName={initialName}
      initialValue={initialValue}
      initialStateIsDirty={initialStateIsDirty}
    >
      <Card
        sx={{ pt: 4, pb: 4, pr: 5, pl: 5 }}
        variant="outlined"
        className={className}
      >
        <SnippetField
          title="Name"
          subheader="Choose a unique, descriptive name that captures the essence of the snippet."
          textField={<SnippetNameTextField />}
        />
        <SnippetField
          title="Text"
          subheader="Enter or paste the content for your snippet here. You can type it manually or copy it from another source."
          textField={<SnippetValueTextField />}
        />
        <SaveOrUpdateSnippetButton />
      </Card>
    </SnippetFormProvider>
  );
};

export default SnippetFormCard;
