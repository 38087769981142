import React from "react";
import Button from "@mui/material/Button";
import type { ButtonSizeTypes, ButtonVariantTypes } from "@toolflow/shared";
import useNavigateToAgentById from "../../workstation/hooks/useNavigateToAgentById";

function NavigateToAgentInWorkspaceButton({
  agentId,
  className,
  variant = "contained",
  text = "Open",
  size = "medium"
}: {
  agentId?: string;
  className?: string;
  variant?: ButtonVariantTypes;
  text?: string;
  size?: ButtonSizeTypes;
}) {
  const handleNavigate = useNavigateToAgentById(agentId);

  if (!agentId) {
    return null;
  }

  return (
    <>
      <Button
        size={size}
        onClick={handleNavigate}
        variant={variant}
        className={className}
      >
        {text}
      </Button>
    </>
  );
}

export default NavigateToAgentInWorkspaceButton;
