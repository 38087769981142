import React from "react";
import ChatHeader from "./chatHeader/ChatHeader";
import ChatThread from "./chatbox/ChatThread";
import useChatSocket from "./chatbox/hooks/useChatSocket";
import LeftPaperWrapper from "../LeftPaperWrapper";

function ChatBoxPage() {
  useChatSocket();
  return (
    <LeftPaperWrapper
      header={<ChatHeader />}
      mainContent={<ChatThread />}
      className="bg-color-right-side-content"
    />
  );
}

export default ChatBoxPage;
