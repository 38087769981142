import React from "react";
import useSetSelectedWorkflowTool from "./hooks/useSetSelectedWorkflowTool";
import UniversalAutocomplete from "../../../../../../utilities/components/autocomplete/universalAutocomplete/UniversalAutocomplete";
import type { TSelectAutocomplete } from "@toolflow/shared";
import { MARKETPLACE_GROUP_TOOL } from "../../../../../../utilities/components/autocomplete/constants/autocompleteConstants";

function WorkflowToolSelectorContainer() {
  const setSelectedTool = useSetSelectedWorkflowTool();
  const handleSelectEntity: TSelectAutocomplete = (id, type) => {
    if (type === MARKETPLACE_GROUP_TOOL) {
      setSelectedTool(id);
    }
  };
  return (
    <UniversalAutocomplete
      margin="normal"
      handleSelectEntity={handleSelectEntity}
      disabledFields={{ workflows: true, useCases: true }}
    />
  );
}

export default WorkflowToolSelectorContainer;
