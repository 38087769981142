import type { LeanFileDocument } from "@toolflow/shared";
import CompressFileCTA from "./CompressFileCTA";
import FileDropdown from "../FileDropdown";
import FileDropzone from "./FileDropzone";
import useFileUpload from "./hooks/useFileUpload";
import SelectedFilePaper from "./SelectedFilePaper";
import { CloseIcon } from "../../../globalTheme/icons/icons";
import RemixButton from "../../../utilities/components/icons/RemixButton";

const NoFileSelectedPaper = ({
  setCurrentFile,
  currentFile,
  disabled
}: {
  setCurrentFile: (file: LeanFileDocument | null) => void;
  currentFile: LeanFileDocument | null;
  disabled?: boolean;
}) => {
  const {
    tmpFile,
    progress,
    abortUpload,
    computedDisabled,
    compressFileCTA,
    handleFileSelect
  } = useFileUpload({ setCurrentFile, disabled });

  if (tmpFile) {
    return (
      <SelectedFilePaper
        action={
          <RemixButton
            icon={CloseIcon}
            size={20}
            onClick={abortUpload}
            className="m-r-8px"
          />
        }
        loading
        progress={progress}
        fileName={tmpFile.name}
        fileSizeInBytes={tmpFile.size}
      />
    );
  }

  return (
    <>
      <FileDropdown
        action={setCurrentFile}
        currentFile={currentFile}
        disabled={computedDisabled}
      />
      <FileDropzone
        handleFileSelect={handleFileSelect}
        disabled={computedDisabled}
      />
      {compressFileCTA && <CompressFileCTA />}
    </>
  );
};

export default NoFileSelectedPaper;
