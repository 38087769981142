import { useMemo } from "react";
import { useFormContext, type FieldValues } from "react-hook-form";

// isDirty gets weird, better to find length of fields
// https://stackoverflow.com/questions/64736717/react-hook-form-isdirty-seems-weird-for-me
function useIsDirty<T extends FieldValues>() {
  const { formState } = useFormContext<T>();

  // we only want dirty fields that return "true"
  const getTrueDirtyFields = (
    fields: Record<string, $TSAllowedAny>
  ): Record<string, $TSAllowedAny> => {
    const result: Record<string, $TSAllowedAny> = {};

    for (const key in fields) {
      if (fields[key] === true) {
        result[key] = true;
      } else if (typeof fields[key] === "object" && fields[key] !== null) {
        const nestedResult = getTrueDirtyFields(fields[key]);
        if (Object.keys(nestedResult).length > 0) {
          result[key] = nestedResult;
        }
      }
    }

    return result;
  };

  const trueDirtyFields = useMemo(() => {
    return getTrueDirtyFields(formState.dirtyFields);
  }, [formState.dirtyFields, formState.isDirty]); // the formState.isDirty is necessary for useSetThreadId

  return {
    isDirty: Object.keys(trueDirtyFields).length > 0,
    dirtyFields: trueDirtyFields,
    formStateIsDirty: formState.isDirty
  };
}

export default useIsDirty;
