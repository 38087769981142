import type { TAgentBuilderForm, TUseCase } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { AGENT_TAG_USE_CASES_FIELD_LABEL } from "../../constants/fields";

const useSetAgentUseCases = () => {
  const { setValue, register } = useFormContext<TAgentBuilderForm>();
  const setAgentUseCases = (useCases: TUseCase[]) => {
    register(AGENT_TAG_USE_CASES_FIELD_LABEL);
    setValue(AGENT_TAG_USE_CASES_FIELD_LABEL, useCases, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };
  return setAgentUseCases;
};

export default useSetAgentUseCases;
