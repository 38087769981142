import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import React from "react";

interface SidebarButtonProps {
  text: string;
  icon: React.ReactNode;
  open?: boolean;
  showSelected?: boolean;
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

interface InternalIconAndTextProps {
  icon: React.ReactNode;
  text: string;
}

export const InternalIconAndText: React.FC<InternalIconAndTextProps> = ({
  icon,
  text
}) => {
  return (
    <>
      <ListItemIcon style={{ color: "inherit" }} className="min-w-42px">
        {icon}
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </>
  );
};

const SidebarButton: React.FC<SidebarButtonProps> = ({
  text,
  icon,
  open,
  showSelected,
  onClick
}) => {
  if (open) {
    return (
      <>
        {onClick ? (
          <ListItemButton
            onClick={onClick}
            selected={showSelected}
            disableTouchRipple
          >
            <InternalIconAndText icon={icon} text={text} />
          </ListItemButton>
        ) : (
          <ListItem>
            <InternalIconAndText icon={icon} text={text} />
          </ListItem>
        )}
      </>
    );
  }

  return (
    <div className={`m-t-8px flex align-i-center justify-center`}>
      <IconButton onClick={onClick}>{icon}</IconButton>
    </div>
  );
};

export default SidebarButton;
