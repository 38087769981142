export type TVariableType = "Asset";
export enum AutocompleteGroups {
  Workflow = "Workflow",
  BlockOutputs = "Block Outputs",
  Tool = "Tool",
  Snippet = "Snippet",
  Inputs = "Inputs", // Toolbuilder inputs
  VariableType = "VariableType",
  Commands = "Commands",
  Asset = "Asset"
}

export type TMarketplaceGroupTool = "Tools";
export type TMarketplaceGroupWorkflow = "Workflows";
export type TMarketplaceGroupUseCase = "Use Cases";

export type MarketplaceAutocompleteGroups =
  | TMarketplaceGroupTool
  | TMarketplaceGroupWorkflow
  | TMarketplaceGroupUseCase;

export type TSelectAutocomplete = (
  id: string,
  type: MarketplaceAutocompleteGroups
) => void;

export type ToolAutocomplete = {
  primary: string;
  id: string;
  secondary: string;
  group: AutocompleteGroups;
  favoriteType?: "star";
};
export type MarketplaceEntityAutocomplete = {
  primary: string;
  id: string;
  secondary: string;
  group: MarketplaceAutocompleteGroups;
  favoriteType?: "star";
  useCases: string[];
};
