import { LeanDocument } from "mongoose";
import { AgentSchemaId, UserSchemaId } from "../schemaTypes";
import { TTagObject, VisibilityTypes } from "./utilities";
import { JSONContent } from "@tiptap/core";

export enum EAgentType {
  OpenAIAssistant = "OpenAIAssistant"
}

export type TInstructions = JSONContent | string;

export type TAgentDataToSaveBase = {
  name: string;
  description: string;
  visibility: VisibilityTypes;
  tag: TTagObject;
  isFeatured: boolean;
  isPopular: boolean;
  userId: string;
  externalId: string;
  type: EAgentType;
  config: {
    instructions: TInstructions;
  };
};

export type TAgentDataToSave = Omit<TAgentDataToSaveBase, "config"> & {
  config: TUniversalAgentConfig;
};

export type TAgentDataToUpdate = Partial<TAgentDataToSave>;

export type TAgentReference = TAgentDataToSaveBase & {
  _id: string;
  createdAt: number;
  updatedAt: number;
};

export type TUniversalAgentConfig = {
  model: string;
  temperature?: number;
  toolIds: string[];
  instructions: TInstructions;
};

export type TUniversalAgent = Omit<TAgentReference, "config"> & {
  config: TUniversalAgentConfig;
};

// apiKeys are toolflow API keys, not api keys to other integrations
// other integration API keys are handled with secrets
export type TAgentReferenceDocument = Omit<TAgentReference, "_id" | "userId"> &
  Document & {
    _id: AgentSchemaId;
    userId?: UserSchemaId;
  };

export type LeanAgentReferenceDocument = LeanDocument<TAgentReferenceDocument>;
