import { Button } from "@mui/material";
import React from "react";
import { useLoginBackToPage } from "./hooks/useLoginBackToPage";

function LoginTextLink({ className = "" }: { className: string }) {
  const { loginBackToPage } = useLoginBackToPage();
  return (
    <Button variant="text" className={className} onClick={loginBackToPage}>
      Login
    </Button>
  );
}

export default LoginTextLink;
