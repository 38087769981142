import type {
  FileSucccessResponse,
  FilesResponseFE,
  LeanFileDocument
} from "@toolflow/shared";
import { authenticatedApi } from "../authenticatedAPI";
import {
  filesAdapter,
  filesInitialState
} from "../../stores/adapters/filesAdapter";
import { type EntityState } from "@reduxjs/toolkit";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import { FILE_TAG_TYPE } from "../cacheTagConstants";

export const fileApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteFile: builder.mutation<FileSucccessResponse, string>({
      query: (id: string) => ({
        url: `file/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: [FILE_TAG_TYPE],
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Deleted file"));
        } catch {
          dispatch(setErrorMessage("Error deleting file"));
        }
      }
    }),
    getFiles: builder.query<EntityState<LeanFileDocument, string>, void>({
      query: () => ({
        url: `file`,
        method: "GET"
      }),
      transformResponse: (response: FilesResponseFE) => {
        return filesAdapter.upsertMany(filesInitialState, response.files);
      },
      providesTags: [FILE_TAG_TYPE]
    })
  })
});

export const fileApiEndpoints = fileApi.endpoints;

export const { useGetFilesQuery, useDeleteFileMutation } = fileApi;
