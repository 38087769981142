import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WORKFLOWBUILDER_BASE_ROUTE_PATH } from "../../../../../navigation/helpers/routePaths";

const useNavigateToNewWorkflow = (hasSaved: boolean, workflowId?: string) => {
  const navigate = useNavigate();
  const navigateToNewWorkflow = () => {
    navigate(`${WORKFLOWBUILDER_BASE_ROUTE_PATH}${workflowId}`);
  };
  useEffect(() => {
    if (hasSaved && workflowId) {
      navigateToNewWorkflow();
    }
  }, [workflowId]);
};

export default useNavigateToNewWorkflow;
