import { AGENT, type TMarketplaceUniversalEntity } from "@toolflow/shared";
import React, { FC } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Typography from "@mui/material/Typography";
import { Box, Divider } from "@mui/material";

import EntityCardHeader from "./EntityCardHeader";
import EntityCardSubHeader from "./EntityCardSubHeader";

import CopyEntityLink from "../../../tools/components/searchToolsContainer/CopyEntityLink";
import DuplicateEntity from "../../../tools/components/searchToolsContainer/DuplicateEntity";
import styles from "./entityCard.module.css";
import MarketplaceBlockTags from "../../../tools/components/modelBlockTags/ModelBlockTags";
import { OwnerActions } from "../../../pages/home/tools/EntityTableRow";
import { useGetCurrentUserProfileId } from "../../../user/hooks/useGetCurrentUserProfileId";
import { parsedClassNames } from "../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

function NotOwnerActions({ entity }: { entity: TMarketplaceUniversalEntity }) {
  if (entity.type === AGENT) {
    return null;
  }
  return (
    <div>
      <CopyEntityLink entityId={entity.id} entityType={entity.type} />
      <DuplicateEntity entityId={entity.id} entityType={entity.type} />
    </div>
  );
}

function ActionSplitter({ entity }: { entity: TMarketplaceUniversalEntity }) {
  const profileId = useGetCurrentUserProfileId();
  if (entity.userId === profileId) {
    return <OwnerActions entity={entity} />;
  } else {
    return <NotOwnerActions entity={entity} />;
  }
}

interface IEntityCardProps {
  entity: TMarketplaceUniversalEntity;
  onClick: (e: React.MouseEvent) => void;
  disableActions?: boolean;
}
const EntityCard: FC<IEntityCardProps> = ({
  entity,
  onClick,
  disableActions
}) => {
  return (
    <Card
      className={cx(styles["entity-card"], {
        [styles["entity-card-disabled-actions"]]: disableActions
      })}
      onClick={onClick}
    >
      <CardHeader
        className={styles["entity-card-header"]}
        disableTypography
        classes={{
          content: "max-w-100-percent"
        }}
        title={<EntityCardHeader entity={entity} />}
        subheader={<EntityCardSubHeader entity={entity} />}
      />
      <CardContent className="p-b-0px p-t-8px h-100px">
        <Box className="h-50px">
          <Typography
            variant="body2"
            color="text.secondary"
            paragraph
            className="m-0px oflow-two-lines"
          >
            {entity.description}
          </Typography>
        </Box>
        <MarketplaceBlockTags
          blockLabels={entity.tag?.blocks || []}
          className="h-40px"
        />
      </CardContent>
      {!disableActions && (
        <>
          <Divider variant="middle" />
          <CardActions
            disableSpacing
            className="flex justify-space-between"
            sx={{ color: "text.disabled" }}
          >
            <div className="m-l-auto">
              <ActionSplitter entity={entity} />
            </div>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default EntityCard;
