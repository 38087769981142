import { useSelector } from "react-redux";
import { useGetCurrentUserProfileId } from "../user/hooks/useGetCurrentUserProfileId";
import { useGetAgentsQuery } from "../../ToolflowAPI/rtkRoutes/agentApi";
import { selectAllAgents } from "../../ToolflowAPI/rtkRoutes/selectors/agentSelectors";

export const useGetCurrentUserAgents = () => {
  const profileId = useGetCurrentUserProfileId();
  const { isFetching, isLoading } = useGetAgentsQuery(undefined, {
    skip: !profileId
  }); // we need this to stay subscribed to the query
  const agents = useSelector(selectAllAgents);

  return {
    agents,
    loading: isLoading || isFetching
  };
};
