import {
  InputType,
  type SerpBlockSettings,
  type TSerpAllowedOptions
} from "@toolflow/shared";

export const serpBlockOutputAllowedValuesDict: TSerpAllowedOptions = {
  peopleAlsoAsk: true,
  relatedQueries: true,
  organicResults: true,
  paidResults: true,
  resultOptions: {
    title: true,
    url: true,
    description: true,
    displayedUrl: true,
    emphasizedKeywords: true,
    siteLinks: true
  }
};
export const serpSettingsInitialState: {
  settings: SerpBlockSettings;
} = {
  settings: {
    inputType: InputType.FixedInput,
    countryCode: "us",
    languageCode: "en",
    maxPagesPerQuery: 1,
    queries: "",
    userQueriesFieldKey: "",
    resultsPerPage: 10,
    allowedOutputs: serpBlockOutputAllowedValuesDict
  }
};
