import { Box, Divider } from "@mui/material";
import {
  BlockNode,
  stringPromptFromUserInput,
  type PerplexityBlockData,
  type PerplexityBlockProps
} from "@toolflow/shared";
import { Fragment, useMemo } from "react";
import { PromptTypeToOutputFieldMap } from "../../../../../tools/components/editorToolCard/inputs/helpers/outputFieldHelpers";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestToolRun from "../../common/TestToolRun";
import EmptyTestBlock from "../components/EmptyTestBlock";
import TestBlockOutput from "../components/TestBlockOutput";
import useTest from "../hooks/useTest";
import useGetFieldsToTestString from "../../hooks/useGetFieldsToTestString";
import useFields from "../hooks/useFields";
import TestFieldsV1 from "../promptBlocks/testFields/TestFieldsv1";

export default function TestPerplexity({
  data,
  id
}: Omit<PerplexityBlockProps, "selected">) {
  const { settings, type } = data;
  const isReady = !!settings.input.length;
  const fieldsToTest = useGetFieldsToTestString(settings.input, id);
  const { userInput, setUserInput, fields } = useFields(fieldsToTest);

  const { action, label, text, endIcon, showUpgrade, loading, output } =
    useTest<PerplexityBlockData>({
      data,
      id,
      blockType: BlockNode.PerplexityBlockNode,
      fields,
      userInput
    });

  const filledOutPrompt = useMemo(
    () => stringPromptFromUserInput(settings.input, userInput),
    [settings.input, userInput]
  );

  return (
    <DrawerBottom
      mainContent={
        <Fragment>
          {isReady ? (
            <Fragment>
              <TestFieldsV1
                uI={userInput}
                setUI={setUserInput}
                filledOutPrompt={filledOutPrompt}
                fields={fieldsToTest}
              />
              <Divider />
              <Box sx={{ marginInline: 2, marginTop: 2, paddingBottom: 2 }}>
                <TestBlockOutput
                  output={output}
                  label={label}
                  type={PromptTypeToOutputFieldMap[type]}
                />
              </Box>
            </Fragment>
          ) : (
            <EmptyTestBlock
              text="Output preview area"
              subText="Test your node here."
            />
          )}
        </Fragment>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          disabled={!isReady}
          loading={loading}
        />
      }
    />
  );
}
