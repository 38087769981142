import convertExternalToolFEToMarketplaceEntity from "../../tools/functions/convertExternalToolFEToMarketplaceEntity";
import { useGetCurrentUserTools } from "../../tools/hooks/useGetCurrentUserTools";
import { useMemo } from "react";

export const useGetAllUserToolsAsEntities = () => {
  const { tools, loading } = useGetCurrentUserTools();
  const entities = useMemo(() => {
    return tools.map(convertExternalToolFEToMarketplaceEntity);
  }, [tools]);
  return {
    entities,
    loading
  };
};
