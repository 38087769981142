import React from "react";
import useGetProfileContent from "./hooks/useGetProfileContent";
import RightSideContainer from "../../../utilities/components/containers/RightSideContainer";
import ProfileDropdownMenu from "./dropdown/ProfileDropdownMenu";
import HeadingWithNavigation from "./pages/HeadingWithNavigation";
import { ThemeProvider } from "@mui/material";
import CenterContentContainer from "../../../utilities/components/containers/CenterContentContainer";
import profilePageTheme from "./theme/profilePageTheme";

const ProfilePage = () => {
  const { content, nestedRouteLabel } = useGetProfileContent();

  return (
    <ThemeProvider theme={profilePageTheme}>
      <RightSideContainer
        topHeaderSection={
          <HeadingWithNavigation
            className="m-16px align-i-center"
            variant="h6"
            text={nestedRouteLabel}
          />
        }
        actionSection={<ProfileDropdownMenu />}
      >
        <CenterContentContainer className="m-t-16px">
          {content}
        </CenterContentContainer>
      </RightSideContainer>
    </ThemeProvider>
  );
};

export default ProfilePage;
