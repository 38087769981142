import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { globalEventEmitterFE } from "../../../../../../../utilities/eventEmitter/globalEventEmitterFE";
import useGetCurrentChatThreadId from "./useGetCurrentChatThreadId";
import {
  setThreadMessagesLoading,
  updateMessagesWithNewSocketMessage
} from "../chatSlice";
import { EMessageRole } from "@toolflow/shared";

const useChatSocket = () => {
  const dispatch = useDispatch();
  const currentThreadId = useGetCurrentChatThreadId();

  const componentId = EMessageRole.ASSISTANT;
  useEffect(() => {
    const handleWsMessage = (emittedString: string) => {
      const emittedMessage = JSON.parse(emittedString);
      if (emittedMessage.componentId === componentId) {
        dispatch(setThreadMessagesLoading(true));
        if (emittedMessage.type === "toolIds") {
          dispatch(
            updateMessagesWithNewSocketMessage({
              role: EMessageRole.ASSISTANT,
              message: emittedMessage.message.join(","),
              id: "socket_fe" + uuidv4()
            })
          );
          dispatch(setThreadMessagesLoading(false));
        } else if (emittedMessage.completed) {
          dispatch(
            updateMessagesWithNewSocketMessage({
              role: EMessageRole.ASSISTANT,
              message: "",
              isLastMessage: true,
              id: "socket_fe_last_" + uuidv4()
            })
          );
          dispatch(setThreadMessagesLoading(false));
        } else if (!isEmpty(emittedMessage.message)) {
          dispatch(
            updateMessagesWithNewSocketMessage({
              role: EMessageRole.ASSISTANT,
              message: emittedMessage.message,
              isLastMessage: false,
              id: "socket_fe_" + uuidv4()
            })
          );
        }
      }
    };

    globalEventEmitterFE.on("ws_message", handleWsMessage);

    return () => {
      globalEventEmitterFE.off("ws_message", handleWsMessage);
    };
  }, [currentThreadId]);
};
export default useChatSocket;
