import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AGENT_BUILDER_BASE_ROUTE_PATH } from "../../../../../navigation/helpers/routePaths";

const useNavigateToNewAgent = (hasSaved: boolean, agentId?: string) => {
  const navigate = useNavigate();
  const navigateToNewAgent = () => {
    navigate(`${AGENT_BUILDER_BASE_ROUTE_PATH}${agentId}`);
  };
  useEffect(() => {
    if (hasSaved && agentId) {
      navigateToNewAgent();
    }
  }, [agentId]);
};

export default useNavigateToNewAgent;
