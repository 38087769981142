import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import TypograpyLink from "../../../../utilities/components/buttons/TypograpyLink";
import { toolflowTextSecondaryColor } from "../../../../globalTheme/muiUtils/appTheme";
import { KNOWLEDGE_ROUTE_PATH } from "../../../navigation/helpers/routePaths";
import { ArrowRightSmallIcon } from "../../../../globalTheme/icons/icons";

const SnippetBreadcrumbs = () => {
  return (
    <div className="m-t-16px">
      <Breadcrumbs
        separator={<ArrowRightSmallIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <TypograpyLink
          linkText="Knowledge"
          color={toolflowTextSecondaryColor}
          to={KNOWLEDGE_ROUTE_PATH}
          key={"knowledge"}
        />
        ,
        <Typography
          key="newSnippet"
          sx={{ color: (theme) => theme.palette.text.primary }}
        >
          New snippet
        </Typography>
      </Breadcrumbs>
    </div>
  );
};

export default SnippetBreadcrumbs;
