import { type SerpBlockProps, type SerpBlockSettings } from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import SerpBlockSettingsComponent from "../../settings/serp/SerpBlockSettings";

function SerpBlockInner({ data, id }: Omit<SerpBlockProps, "selected">) {
  const { label, settings } = data;
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;

  return (
    <>
      <SerpBlockSettingsComponent
        id={id}
        settings={settings as SerpBlockSettings}
      />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );
}

export default SerpBlockInner;
