import { Block } from "@toolflow/shared";
import DraggableBlockContainer from "../blocks/components/draggableBlock/DraggableBlockContainer";
import styles from "./nodeSelector.module.css";

const DIVIDER = "divider";

export default function NodeSelector() {
  const blocks: (Block | typeof DIVIDER)[] = [
    Block.textGeneration,
    Block.imageGeneration,
    Block.speechToText,
    Block.scraper,
    Block.structured,
    Block.perplexity,
    DIVIDER,
    Block.iterationStart,
    Block.iterationExit,
    Block.serp,
    Block.logic,
    DIVIDER,
    Block.constant,
    Block.embedded
  ];
  return (
    <div className={styles.paper}>
      {blocks.map((block, idx) =>
        block === DIVIDER ? (
          <div className={styles.divider} key={idx} />
        ) : (
          <DraggableBlockContainer key={block} type={block} />
        )
      )}
    </div>
  );
}
