import {
  Block,
  BlockNode,
  BlockType,
  DEFAULT_VERSION_VALUE,
  OpenAiTextModel,
  UtilBlockType,
  type IterationExitBlockData,
  type DeepgramBlockData,
  type IterationStartBlockData,
  type PerplexityBlockData,
  type PromptBlockDataV1,
  type PromptBlockDataV2,
  type ScraperBlockData,
  type SerpBlockData,
  type VersionedBlockConfig
} from "@toolflow/shared";
import { cloneDeep } from "lodash";
import {
  ConstantIcon,
  CrawlerIcon,
  DataExtractorIcon,
  ImageGenerationIcon,
  IterationEndIcon,
  IterationStartIcon,
  LogicIcon,
  SpeechToTextFillIcon,
  ToolIcon
} from "../../../../../../globalTheme/icons/icons";
import GoogleLogo from "../../../../../../globalTheme/icons/logos/GoogleLogo";
import OpenAILogo from "../../../../../../globalTheme/icons/logos/OpenAILogo";
import PerplexityLogo from "../../../../../../globalTheme/icons/logos/PerplexityLogo";
import {
  dallEPromptInitialState,
  deepgramTranscribePromptInitialState,
  iterationExitSettingsInitialState,
  iterationStartSettingsInitialState,
  promptInitialState,
  scraperSettingsInitialState,
  serpSettingsInitialState
} from "../../context/initialStates";
import { perplexityInitialState } from "../../context/initialStates/perplexityInitialState";
import { structuredInitialState } from "../../context/initialStates/structuredInitialState";
import ConstantBlockInner from "../constantBlock/ConstantBlockInner";
import DeepgramBlockInner from "../deepgram/DeepgramBlockInner";
import TestDeepgram from "../deepgram/TestDeepgram";
import IterationExitBlockInner from "../IterationExitBlock/IterationExitBlockInner";
import IterationStartBlockInner from "../IterationStartBlock/IterationStartBlockInner";
import LogicInner from "../logic/LogicInner";
import PerplexityBlockInner from "../perplexityBlock/PerplexityBlockInner";
import TestPerplexity from "../perplexityBlock/TestPerplexity";
import PromptBlockInner from "../promptBlocks/versions/1.0/PromptBlockInner";
import TestPrompt from "../promptBlocks/versions/1.0/TestPrompt";
import PromptBlockInnerv2 from "../promptBlocks/versions/2.0/PromptBlockInnerv2";
import TestPromptV2 from "../promptBlocks/versions/2.0/TestPromptV2";
import ScraperBlockInner from "../scraperBlock/ScraperBlockInner";
import TestScraper from "../scraperBlock/TestScraper";
import SerpBlockInner from "../serpBlock/SerpBlockInner";
import TestSerp from "../serpBlock/TestSerp";
import StructuredBlockInner from "../structuredBlock/StructuredBlockInner";
import TestStructured from "../structuredBlock/TestStructured";
import ToolWithinToolBlockInner from "../toolWithinTool/ToolWithinToolBlockInner";
import { blockValidators } from "./blockValidators";
import getBlockConfigVersion from "./helpers/getBlockConfigVersion";
import { getLatestBlockVersion } from "./helpers/getLatestBlockVersion";

export const blockConfig: Record<Block, VersionedBlockConfig> = {
  [Block.logic]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: LogicIcon,
    drawerComponent: LogicInner,
    blockPaperLabel: "Logic",
    validate: blockValidators[UtilBlockType.Logic],
    draggableItem: {
      type: BlockNode.LogicBlockNode,
      data: {
        label: "Logic",
        type: UtilBlockType.Logic,
        logicArray: []
      }
    }
  }),
  [Block.constant]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: ConstantIcon,
    drawerComponent: ConstantBlockInner,
    blockPaperLabel: "Text composer",
    validate: blockValidators[UtilBlockType.Constant],
    draggableItem: {
      type: BlockNode.ConstantBlockNode,
      data: {
        label: "Text",
        type: UtilBlockType.Constant,
        constant: ""
      }
    }
  }),
  [Block.textGeneration]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      drawerComponent: PromptBlockInner,
      expandedComponent: TestPrompt,
      blockPaperLabel: "Text generator",
      icon: OpenAILogo,
      validate: blockValidators[BlockType.ChatGPT],
      draggableItem: {
        type: BlockNode.PromptBlockNode,
        data: {
          type: BlockType.ChatGPT,
          label: "LLM output",
          ...promptInitialState
        } as PromptBlockDataV1
      }
    }),
    ...getBlockConfigVersion("2.0", {
      drawerComponent: PromptBlockInnerv2,
      expandedComponent: TestPromptV2,
      blockPaperLabel: "Text generator",
      icon: OpenAILogo,
      validate: blockValidators[BlockType.ChatGPT],
      draggableItem: {
        type: BlockNode.PromptBlockNode,
        data: {
          type: BlockType.ChatGPT,
          label: "LLM output",
          settings: {
            prompt: "",
            llmModel: OpenAiTextModel.GPT4o,
            temperature: 50
          }
        } as PromptBlockDataV2
      }
    })
  },
  [Block.imageGeneration]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    drawerComponent: PromptBlockInner,
    expandedComponent: TestPrompt,
    blockPaperLabel: "Image generator",
    icon: ImageGenerationIcon,
    validate: blockValidators[BlockType.DallE],
    draggableItem: {
      type: BlockNode.PromptBlockNode,
      data: {
        type: BlockType.DallE,
        label: "Image Output Name",
        ...dallEPromptInitialState
      } as PromptBlockDataV1
    }
  }),
  [Block.speechToText]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: SpeechToTextFillIcon,
    drawerComponent: DeepgramBlockInner,
    expandedComponent: TestDeepgram,
    blockPaperLabel: "Speech to text",
    validate: blockValidators[BlockType.Deepgram],
    draggableItem: {
      type: BlockNode.DeepgramBlockNode,
      data: {
        ...deepgramTranscribePromptInitialState,
        type: BlockType.Deepgram,
        label: "Transcript"
      } as DeepgramBlockData
    }
  }),
  [Block.scraper]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: CrawlerIcon,
    expandedComponent: TestScraper,
    drawerComponent: ScraperBlockInner,
    blockPaperLabel: "Web scraper",
    validate: blockValidators[BlockType.Scraper],
    draggableItem: {
      type: BlockNode.ScraperBlockNode,
      data: {
        ...scraperSettingsInitialState,
        type: BlockType.Scraper,
        label: "Website content"
      } as ScraperBlockData
    }
  }),
  [Block.serp]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: GoogleLogo,
    drawerComponent: SerpBlockInner,
    expandedComponent: TestSerp,
    blockPaperLabel: "Google search",
    validate: blockValidators[BlockType.SERP],
    draggableItem: {
      type: BlockNode.SerpBlockNode,
      data: {
        ...serpSettingsInitialState,
        type: BlockType.SERP,
        label: "Google Search Results"
      } as SerpBlockData
    }
  }),
  [Block.structured]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: DataExtractorIcon,
      expandedComponent: TestStructured,
      drawerComponent: StructuredBlockInner,
      blockPaperLabel: "Data extractor",
      validate: blockValidators[BlockType.Structured],
      draggableItem: {
        type: BlockNode.StructuredBlockNode,
        data: {
          ...cloneDeep(structuredInitialState),
          type: BlockType.Structured,
          label: "Data extractor"
        }
      }
    })
  },
  [Block.perplexity]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: PerplexityLogo,
    drawerComponent: PerplexityBlockInner,
    expandedComponent: TestPerplexity,
    blockPaperLabel: "Perplexity",
    validate: blockValidators[BlockType.Perplexity],
    draggableItem: {
      type: BlockNode.PerplexityBlockNode,
      data: {
        ...perplexityInitialState,
        type: BlockType.Perplexity,
        label: "Perplexity research"
      } as PerplexityBlockData
    }
  }),
  [Block.embedded]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: ToolIcon,
    drawerComponent: ToolWithinToolBlockInner,
    blockPaperLabel: "Embedded Tool",
    validate: blockValidators[UtilBlockType.ToolWithinTool],
    draggableItem: {
      type: BlockNode.ToolWithinToolBlockNode,
      data: {
        label: "Embedded Tool",
        type: UtilBlockType.ToolWithinTool,
        tool: null,
        inputMap: null
      }
    }
  }),
  [Block.iterationStart]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: IterationStartIcon,
    drawerComponent: IterationStartBlockInner,
    blockPaperLabel: "Start iteration",
    validate: blockValidators[BlockType.IterationStart],
    draggableItem: {
      type: BlockNode.IterationStartBlockNode,
      data: {
        ...iterationStartSettingsInitialState,
        type: BlockType.IterationStart,
        label: "Start iteration"
      } as IterationStartBlockData
    }
  }),
  [Block.iterationExit]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: IterationEndIcon,
    drawerComponent: IterationExitBlockInner,
    validate: blockValidators[BlockType.IterationExit],
    blockPaperLabel: "Exit iteration",
    draggableItem: {
      type: BlockNode.IterationExitBlockNode,
      data: {
        ...iterationExitSettingsInitialState,
        type: BlockType.IterationExit,
        label: "Exit iteration"
      } as IterationExitBlockData
    }
  })
};

export function getBlockConfig(
  block: Block,
  version = DEFAULT_VERSION_VALUE
): (typeof blockConfig)[Block][string] {
  const blockVersions = blockConfig[block];

  return blockVersions[version] || blockVersions[DEFAULT_VERSION_VALUE];
}

export function getLatestBlockConfig(type: Block) {
  return getBlockConfig(type, getLatestBlockVersion(type));
}
