import React from "react";
import { useParams } from "react-router-dom";
import useGetWorkflowName from "../../workflowBuilder/hooks/useGetWorkflowName";
import useGetWorkflowVisibility from "../../workflowBuilder/hooks/useGetWorkflowVisibility";
import SettingsDropdown from "../../../../../utilities/components/dropdowns/generic/SettingsDropdown";
import AddWorkflowCategoriesMenuItem from "../../../../workflow/menuItems/AddWorkflowCategoriesMenuItem";
import CopyWorkflowLinkMenuItem from "../../../../workflow/menuItems/CopyWorkflowLinkMenuItem";
import DeleteMenuItem from "../../../../../utilities/components/dropdowns/menuItems/deleteMenuItem/DeleteMenuItem";
import UpdateWorkflowVisibilityMenuItem from "../../../../workflow/menuItems/UpdateWorkflowVisibilityMenuItem";
import ResetWorkflowMenuItem from "./menuItems/ResetWorkflowMenuItem";
import { EntityType, VisibilityTypes } from "@toolflow/shared";

function WorkflowBuilderHeaderSettingsDropdown() {
  const { workflowId = "" } = useParams();
  const name = useGetWorkflowName();
  const visibility = useGetWorkflowVisibility();
  const menuItems = [
    <CopyWorkflowLinkMenuItem key="copyWorkflowLink" workflowId={workflowId} />,
    <UpdateWorkflowVisibilityMenuItem
      key="updateWorkflowVisibility"
      id={workflowId}
      name={name}
      visibility={visibility || VisibilityTypes.PRIVATE}
    />,
    <ResetWorkflowMenuItem key="resetWorkflow" />,
    <AddWorkflowCategoriesMenuItem
      key="addWorkflowCategories"
      workflowId={workflowId}
    />,
    <DeleteMenuItem
      id={workflowId}
      shouldNavigateHome
      type={EntityType.WORKFLOW}
      key="delete"
    />
  ];

  if (!workflowId) return null;

  return <SettingsDropdown menuItems={menuItems} />;
}

export default WorkflowBuilderHeaderSettingsDropdown;
