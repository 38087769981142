import React from "react";
import UseCaseChips from "./UseCaseChips";
import useSelectUseCases from "./hooks/useSelectUseCases";

const UseCaseChipsContainer = () => {
  const { handleToggleUseCaseSelection, useCases } = useSelectUseCases();
  return (
    <UseCaseChips
      useCases={useCases}
      handleToggleUseCaseSelection={handleToggleUseCaseSelection}
    />
  );
};

export default UseCaseChipsContainer;
