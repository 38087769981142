import React from "react";
import { useGetInitialStateQuery } from "../../ToolflowAPI/rtkRoutes/initialStateApi";
import { useRerouteToCalendly } from "./hooks/useRerouteToCalendly";

export function AuthenticationWrapper({
  children
}: {
  children: React.ReactNode;
}) {
  useGetInitialStateQuery();
  const rerouteToCalendly = useRerouteToCalendly();
  if (rerouteToCalendly) {
    window.location.replace(
      "https://calendly.com/alfie-marsh-toolflow/toolflow-beta-onboarding"
    );
    return <></>;
  }
  return <>{children}</>;
}
