import type { TSetFormState, UserInputDictType } from "@toolflow/shared";
import { LabelAndInfo } from "../../../utilities/components/typographies/LabelAndInfo";
import FileUploadButtonInner from "./FileUploadButtonInner";
import useCurrentFileFromFormState from "./hooks/useCurrentFileFromFormState";

interface FileUploadButtonProps {
  setFormState: TSetFormState;
  useIds?: boolean;
  id?: string;
  description?: string;
  name: string;
  formState: UserInputDictType;
  disabled?: boolean;
}

const FileUploadButton = ({
  useIds,
  id = "",
  name,
  formState,
  setFormState,
  description,
  disabled
}: FileUploadButtonProps) => {
  const { currentFile, setCurrentFile } = useCurrentFileFromFormState({
    formState,
    setFormState,
    useIds,
    id,
    name
  });

  return (
    <>
      <LabelAndInfo name={name} info={description} />
      <FileUploadButtonInner
        currentFile={currentFile}
        setCurrentFile={setCurrentFile}
        disabled={disabled}
      />
    </>
  );
};

export default FileUploadButton;
