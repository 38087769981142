import { Content } from "@tiptap/core";
import { TThreadReference } from "../models";

export type TGetThreadRequest = {
  threadId: string;
};

export type TGetThreadParams = {
  threadId: string;
};

export type TGetThreadMessagesResponse = {
  result: { messages: TMessageByThreadId[] };
  success: boolean;
  error: string | null;
};

export enum EMessageRole {
  USER = "user",
  ASSISTANT = "assistant",
  SYSTEM = "system"
}

export type TMessageByThreadId = {
  role: EMessageRole;
  message: string;
  id: string;
  isLastMessage?: boolean;
};

export type TTransformedMessage = {
  role: EMessageRole;
  content: Content;
  id: string;
};

export type TResetThreadResponse = {
  result: { threadId: string };
  success: boolean;
  error: string | null;
};
export type TAbortThreadResponse = {
  result: { message: string };
  success: boolean;
  error: string | null;
};

export type GetUserThreadsResponse = {
  threads: TThreadReference[];
};
