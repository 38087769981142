import type { Content } from "@tiptap/core";
import {
  type StringAsset,
  AssetSourceType,
  AssetValueType
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import { VARIABLE_NAME } from "../../helpers/workspaceConstants";

export const getNewStringAsset = (value: Content = ""): StringAsset => {
  const id = uuidv4();
  return {
    label: `New ${VARIABLE_NAME.toLowerCase()}`,
    name: `New ${VARIABLE_NAME.toLowerCase()}`,
    source: AssetSourceType.Manual,
    value: value || "",
    valueType: AssetValueType.String,
    id
  };
};
