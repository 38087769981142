import { useDropzone } from "react-dropzone";
import CircleIcon from "../../../utilities/components/icons/CircleButton";
import { Typography } from "@mui/material";
import { UploadIcon } from "../../../globalTheme/icons/icons";
import styles from "./fileDropzone.module.css";
import { parsedClassNames } from "../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

const FileDropzone = ({
  handleFileSelect,
  disabled
}: {
  handleFileSelect: (files: File[]) => void;
  disabled?: boolean;
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: handleFileSelect,
    disabled
  });
  return (
    <div
      {...getRootProps()}
      className={cx(styles["dropzone-style"], { "cursor-pointer": !disabled })}
      onClick={open}
    >
      <input {...getInputProps()} />
      <CircleIcon size={20} Icon={UploadIcon} />
      <Typography variant="h8" align="center" className="m-t-8px">
        Click to upload or drag and drop
      </Typography>
      <Typography
        variant="body2"
        align="center"
        sx={{ color: (theme) => theme.palette.text.secondary }}
      >
        MP3/WAV/M4A/AAC, max 500 MB/2 hours
      </Typography>
    </div>
  );
};

export default FileDropzone;
