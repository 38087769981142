import { OpenAiImageModel, OpenAiTextModel } from "@toolflow/shared";
import { anthropicInitialOptimizations } from "./anthropicInitialState";

export const promptInitialState = {
  prompt: "",
  optimizations: {
    llmModel: OpenAiTextModel.GPT4o,
    temperature: 50
  },
  anthropicOptimizations: anthropicInitialOptimizations
};

export const dallEInitialOptimizations = {
  size: "512x512",
  model: OpenAiImageModel.DALLE2,
  n: 1
};
export const dallE2InitialOptimizations = {
  size: "512x512",
  model: OpenAiImageModel.DALLE2,
  n: 1
};

export const dallE3InitialOptimizations = {
  size: "1024x1024",
  model: OpenAiImageModel.DALLE3,
  n: 1,
  style: "vivid",
  quality: "standard"
};

export const dallEPromptInitialState = {
  prompt: "",
  optimizations: dallEInitialOptimizations
};

export const dallE3PromptInitialState = {
  prompt: "",
  optimizations: dallE3InitialOptimizations
};
