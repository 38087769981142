import type { TAgentBuilderForm } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { AGENT_TAG_FIELD_LABEL } from "../../constants/fields";

const useGetAgentUseCases = () => {
  const { watch } = useFormContext<TAgentBuilderForm>();
  return watch(AGENT_TAG_FIELD_LABEL)?.useCases || [];
};

export default useGetAgentUseCases;
