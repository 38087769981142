import React from "react";
import { Typography } from "@mui/material";

function TermsSeperator() {
  return (
    <Typography variant="caption" className={"m-h-5px grey"}>
      &middot;
    </Typography>
  );
}

export default TermsSeperator;
