import {
  FieldType,
  type FieldConfig,
  type ScraperBlockProps
} from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";
import useUpdateField from "../../useUpdateField";

function ScraperBlockInner({ data, id }: Omit<ScraperBlockProps, "selected">) {
  const { label, settings } = data;
  useSyncCurrentAndPrevSettings(settings);
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;

  const configs: FieldConfig = {
    fields: [
      {
        type: FieldType.PromptInput,
        label: "Url",
        config: {
          prependKey: "settings",
          fieldKey: "urlFieldInputKey",
          maxRows: 1,
          minRows: 1
        }
      },
      {
        type: FieldType.SelectInputV1,
        label: "Preferred Result Type",
        config: {
          fieldKey: "preferredScraperResultType",
          options: ["markdown", "html", "text"]
        }
      }
    ]
  };

  const updateField = useUpdateField(id);

  return (
    <div className="m-t-16px">
      <FieldsByType
        id={id}
        settings={settings}
        configs={configs}
        updateField={updateField}
      />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </div>
  );
}

export default ScraperBlockInner;
