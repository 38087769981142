import React from "react";

import ToggleSidebarButton from "../../../layout/sidebar/ToggleSidebarButton";
import { useSelector } from "react-redux";
import { selectSidebarOpen } from "../../../layout/sidebar/sidebarSlice";
import AuthenticationSplitter from "../../../auth/AuthenticationSplitter";
import LoggedOutCTA from "../../external/LoggedOutCTA";
import ProfileDropdownMenu from "../../profile/dropdown/ProfileDropdownMenu";
import ToggleAgentNameTextField from "./ToggleAgentNameTextField";
import ToggleAgentDescriptionTextField from "./ToggleAgentDescriptionTextField";
import SaveAgentDialog from "./SaveAgentDialog/SaveAgentDialog";
import NavigateToAgentInWorkspaceButton from "./NavigateToAgentInWorkspaceButton";
import { useParams } from "react-router-dom";

function AgentHeader() {
  const open = useSelector(selectSidebarOpen);
  const { agentId = "" } = useParams();

  return (
    <div className="flex align-i-center m-h-24px p-v-8px">
      {!open && <ToggleSidebarButton className="m-l-8px" />}
      <div className="w-100-percent flex align-i-center justify-space-between">
        <div className="flex align-i-center w-100-percent">
          <ToggleAgentNameTextField />
          <ToggleAgentDescriptionTextField />
        </div>
        <div className="flex align-i-center">
          <SaveAgentDialog className="m-r-8px" />
          <NavigateToAgentInWorkspaceButton agentId={agentId} />
          <AuthenticationSplitter
            externalComponent={<LoggedOutCTA />}
            authenticatedComponent={<ProfileDropdownMenu size={32} />}
          />
        </div>
      </div>
    </div>
  );
}

export default AgentHeader;
