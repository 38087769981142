import { MenuItem, Select, Typography } from "@mui/material";
import { DynamicFieldType } from "@toolflow/shared";
import { ReactNode } from "react";
import { ArrowDownSmallIcon } from "../../../../../../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import styles from "./dynamicFieldWrapper.module.css";

const cx = parsedClassNames.bind(styles);

export default function DynamicFieldWrapper({
  label,
  subtitle,
  value = DynamicFieldType.Dynamic,
  onChange,
  children
}: {
  label: string;
  subtitle?: string;
  value: DynamicFieldType;
  children: ReactNode;
  onChange?(value: DynamicFieldType): void;
}) {
  return (
    <div className={cx("p-16px border-radius-8px", "container")}>
      <Typography variant="subtitle1" fontWeight="500">
        {label}
      </Typography>
      {subtitle && (
        <Typography variant="caption" color="text.secondary">
          {subtitle}
        </Typography>
      )}

      <Select
        value={value}
        size="small"
        fullWidth
        onChange={(event) => onChange?.(event.target.value as DynamicFieldType)}
        className={cx("select-field")}
        variant="standard"
        IconComponent={() => (
          <ArrowDownSmallIcon className={cx("select-field-icon")} />
        )}
      >
        <MenuItem
          className={cx("select-field-item")}
          value={"dynamic"}
          key={"dynamic"}
        >
          🎛️ Dynamic value
        </MenuItem>
        <MenuItem
          className={cx("select-field-item")}
          value={"preset"}
          key={"preset"}
        >
          🔒 Pre-set value
        </MenuItem>
      </Select>

      {children}
    </div>
  );
}
