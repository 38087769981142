import { createSelector } from "@reduxjs/toolkit";
import {
  externalToolAdapter,
  externalToolsInitialState
} from "../../../stores/adapters/externalToolAdapters";
import {
  marketplaceEntityAdapter,
  marketplaceEntityAdapterInitialState
} from "../../../stores/adapters/marketplaceEntityAdapter";
import { RootState } from "../../../stores/store";
import { toolsApi } from "../toolsApi";
import { marketplaceApi } from "../marketplaceApi";
import {
  type TEntityFilterByCategoriesFE,
  type TEntityFiltersByUseCasesFE,
  type TDisabledEntities,
  EntityType,
  type TMarketplaceUniversalEntity
} from "@toolflow/shared";
import { memoize } from "lodash";
import { favoritesApi } from "../favoritesApi";

const selectProfileToolResults = (profileId: string) =>
  toolsApi.endpoints.getProfileTools.select(profileId);

const selectStarredToolResults = (profileId: string) =>
  favoritesApi.endpoints.getProfileFavoriteEntities.select(profileId);

const selectStarredToolData = (profileId: string) =>
  createSelector(
    selectStarredToolResults(profileId),
    (profileToolResult) =>
      profileToolResult.data ?? marketplaceEntityAdapterInitialState
  );

export const selectAllStarredTools = (profileId: string) =>
  createSelector(
    (state: RootState) => selectStarredToolData(profileId)(state),
    (entityData) =>
      marketplaceEntityAdapter.getSelectors().selectAll(entityData)
  );

const selectProfileToolData = (profileId: string) =>
  createSelector(
    selectProfileToolResults(profileId),
    (profileToolResult) => profileToolResult.data ?? externalToolsInitialState
  );

export const selectAllProfileTools = (profileId: string) =>
  createSelector(
    (state: RootState) => selectProfileToolData(profileId)(state),
    (toolData) => externalToolAdapter.getSelectors().selectAll(toolData)
  );

export const selectProfileToolsById = (profileId: string, toolId: string) =>
  createSelector(
    (state: RootState) => selectProfileToolData(profileId)(state),
    (toolData) =>
      externalToolAdapter.getSelectors().selectById(toolData, toolId)
  );

const selectMarketplaceEntitiesResult =
  marketplaceApi.endpoints.getMarketplaceEntities.select();

const selectMarketplaceEntitiesData = createSelector(
  selectMarketplaceEntitiesResult,
  (marketplaceEntitiesResult) => marketplaceEntitiesResult.data
);

export const {
  selectAll: selectAllMarketplaceEntities,
  selectById: selectMarketplaceEntityById
} = marketplaceEntityAdapter.getSelectors(
  (state: RootState) =>
    selectMarketplaceEntitiesData(state) ?? marketplaceEntityAdapterInitialState
);

const getFilteredToolsByUseCases = (
  entities: TMarketplaceUniversalEntity[],
  useCases: TEntityFiltersByUseCasesFE
) => {
  let filteredTools: TMarketplaceUniversalEntity[] = [];
  if (useCases.length === 0) {
    filteredTools = [...entities];
  } else {
    filteredTools = entities
      .filter(
        (entity) =>
          useCases.filter((useCase) =>
            (entity?.tag?.useCases || []).includes(useCase.toString())
          ).length > 0
      )
      .flat();
  }
  return filteredTools;
};

const filterDisabledEntities = (
  entities: TMarketplaceUniversalEntity[],
  disabledEntities?: TDisabledEntities
): TMarketplaceUniversalEntity[] => {
  return entities.filter((entity) => {
    if (disabledEntities) {
      if (entity.type === EntityType.TOOL && disabledEntities.tools) {
        return false;
      }
      if (entity.type === EntityType.WORKFLOW && disabledEntities.workflows) {
        return false;
      }
    }
    return true;
  });
};

export const filterMarketplaceEntities = (
  tools: TMarketplaceUniversalEntity[],
  {
    selectedCategory,
    selectedUseCases
  }: {
    selectedCategory: TEntityFilterByCategoriesFE;
    selectedUseCases: TEntityFiltersByUseCasesFE;
  },
  disabledEntities?: TDisabledEntities
): TMarketplaceUniversalEntity[] => {
  let filteredTools: TMarketplaceUniversalEntity[] = [...tools];

  if (selectedCategory === "popular") {
    filteredTools = tools.filter((entity) => entity.isPopular);
  } else if (selectedCategory !== "all") {
    filteredTools = tools.filter((entity) =>
      (entity?.tag?.categories || []).includes(selectedCategory)
    );
  }

  return filterDisabledEntities(
    getFilteredToolsByUseCases(filteredTools, selectedUseCases),
    disabledEntities
  );
};

export const selectMarketplaceEntitiesByFilters = ({
  selectedCategory,
  selectedUseCases
}: {
  selectedCategory: TEntityFilterByCategoriesFE;
  selectedUseCases: TEntityFiltersByUseCasesFE;
}) =>
  createSelector([selectAllMarketplaceEntities], (tools) =>
    filterMarketplaceEntities(tools, { selectedCategory, selectedUseCases })
  );

export const memoizedSelectMarketplaceEntitiesByFilters = memoize(
  selectMarketplaceEntitiesByFilters
);
