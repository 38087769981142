import { Box, Stack, Typography } from "@mui/material";
import type {
  CustomToolOutputField,
  ToolOutputFieldConfig
} from "@toolflow/shared";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import useGetCurrentToolOutputField from "./customToolOutputFields/hooks/useGetCurrentToolOutputField";
import useGetCurrentToolOutputFieldIndex from "./customToolOutputFields/hooks/useGetCurrentToolOutputFieldIndex";
import useIsIterationChild from "./hooks/useIsIterationChild";
import { Switch } from "./outputFields/Switch";

function OutputFieldsInner({
  toolOutputField
}: {
  toolOutputField: CustomToolOutputField;
}) {
  const index = useGetCurrentToolOutputFieldIndex();
  const { dispatch } = useToolbuilderContext();

  const handleOutputConfigsUpdate = (
    configs: Partial<ToolOutputFieldConfig>
  ) => {
    dispatch({
      type: "UPDATE_TOOL_OUTPUT_FIELD_CONFIG",
      index,
      configs
    });
  };

  return (
    <Box sx={{ marginTop: 5 }}>
      <Typography variant="subtitleLarge" color="text.primary">
        Output Configuration
      </Typography>

      <Stack spacing={2} sx={{ mt: 2 }}>
        <Switch
          label="Output results in separate assets"
          checked={!!toolOutputField.config?.splitOutputs}
          onChange={(checked) =>
            handleOutputConfigsUpdate({ splitOutputs: checked })
          }
        />
      </Stack>
    </Box>
  );
}

export default function OutputConfigFields() {
  const toolOutputField = useGetCurrentToolOutputField();
  const isIterationChild = useIsIterationChild(toolOutputField);

  if (!isIterationChild) return null;

  return <OutputFieldsInner toolOutputField={toolOutputField} />;
}
