import {
  EXTERNAL_TOOL_ROUTE_PATH,
  EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH,
  EXTERNAL_WORKSPACE_ROUTE_PATH,
  KNOWLEDGE_ROUTE_PATH,
  AGENT_BUILDER_ROUTE_PATH,
  AGENTS_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  TOOLS_PAGE_ROUTE_PATH,
  SETTINGS_ROUTE_PATH,
  SIGNUP_ROUTE_PATH,
  TOOLBUILDER_ROUTE_PATH,
  WORKFLOWBUILDER_ROUTE_PATH,
  WORKSPACE_ROUTE_PATH,
  BASE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_ROUTE_PATH,
  SNIPPET_ROUTE_PATH,
  HOME_ROUTE_PATH_DEV,
  PROFILE_ROUTE_PATH
} from "./routePaths";

// update "/settings/ and "/t/

const routeTitleDict = {
  [HOME_ROUTE_PATH_DEV]: "Home",
  [BASE_ROUTE_PATH]: "Workspace",
  [TOOLBUILDER_ROUTE_PATH]: "Tool Builder",
  [KNOWLEDGE_ROUTE_PATH]: "Knowledge",
  [AGENT_BUILDER_ROUTE_PATH]: "Agent Builder",
  [AGENTS_ROUTE_PATH]: "Agents",
  [SNIPPET_ROUTE_PATH]: "Snippet",

  [WORKFLOWBUILDER_ROUTE_PATH]: "Workflow Builder",

  [LOGIN_ROUTE_PATH]: "Login",
  [SIGNUP_ROUTE_PATH]: "Sign Up",

  [SETTINGS_ROUTE_PATH]: "Settings",
  [PROFILE_ROUTE_PATH]: "Profile",

  [TOOLS_PAGE_ROUTE_PATH]: "Tools",

  [WORKSPACE_ROUTE_PATH]: "Workspace",

  [EXTERNAL_TOOL_ROUTE_PATH]: "Tool",

  [EXTERNAL_WORKFLOW_ROUTE_PATH]: "Workflow",

  [EXTERNAL_WORKSPACE_ROUTE_PATH]: "Workspace",

  [EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH]: "Tool Response"
};

export default routeTitleDict;
