import { useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useTrackEventMutation } from "../../../ToolflowAPI/rtkRoutes/trackEventApi";
import { isLoginOrConsentRequiredError } from "../../auth/functions/isLoginOrConsentRequiredError";

const Callback = () => {
  const { handleRedirectCallback, loginWithRedirect } = useAuth0();
  const [trackEvent] = useTrackEventMutation();
  const navigate = useNavigate();
  const shouldRedirect = useRef(true);

  useEffect(() => {
    // note if the handleRedirectCallback errors with state
    // it is because of react strict mode
    // https://community.auth0.com/t/error-invalid-state-when-calling-handleredirectcallback-on-react-app/95329/3
    if (shouldRedirect.current) {
      shouldRedirect.current = false;
      const logoutRedirect = localStorage.getItem("logoutRedirect");
      const hasLogoutRedirect = !!logoutRedirect;
      if (hasLogoutRedirect) {
        localStorage.setItem("logoutRedirect", "");
        navigate(logoutRedirect || "/");
      } else {
        const processCallback = async () => {
          try {
            console.log("processing callback");
            const { appState } = await handleRedirectCallback();
            const returnTo = appState?.returnTo ? appState.returnTo : "/";
            trackEvent({
              eventString: "Login completed"
            });
            navigate(returnTo);
          } catch (e) {
            if (isLoginOrConsentRequiredError(e)) {
              loginWithRedirect({
                appState: {
                  returnTo: "/"
                }
              });
            }
            console.log(e);
          }
        };
        processCallback();
      }
    }
  }, [handleRedirectCallback, navigate]);

  return null;
};

export default Callback;
