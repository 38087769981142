import React from "react";
import PricingSection from "./PricingSection";
import UsageSection from "./UsageSection";

const BillingPageContent = () => {
  return (
    <div className="p-b-32px">
      <UsageSection />
      <PricingSection className="m-t-16px" />
    </div>
  );
};

export default BillingPageContent;
