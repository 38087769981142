import type { UpdateField } from "@toolflow/shared";
import React, { createContext, useContext } from "react";

interface FieldsByTypeContextType {
  updateField: UpdateField;
}

const FieldsByTypeContext = createContext<FieldsByTypeContextType | undefined>(
  undefined
);

export function FieldsByTypeContextComponent({
  children,
  updateField
}: Readonly<{
  children: React.ReactNode;
  updateField?: UpdateField;
}>) {
  if (!updateField) {
    return null;
  }
  return (
    <FieldsByTypeContext.Provider value={{ updateField }}>
      {children}
    </FieldsByTypeContext.Provider>
  );
}

export const useFieldsByTypeContext = () => {
  const context = useContext(FieldsByTypeContext);
  if (context === undefined) {
    throw new Error(
      "useFieldsByTypeContext must be used within a FieldsByTypeProvider"
    );
  }
  return context;
};

export default FieldsByTypeContext;
