import { BlockNode, BlockType, type TBlock } from "@toolflow/shared";
import { useBlockContext } from "../BlockContext";
import { useToolbuilderContext } from "../context/ToolBuilderContext";

export function checkIsIterationChild(blocks: TBlock[]) {
  let hasStartIteration = false;
  let hasExitIteration = false;
  blocks.forEach((b) => {
    if (b.type === BlockNode.IterationStartBlockNode) hasStartIteration = true;
    if (b.type === BlockNode.IterationExitBlockNode) hasExitIteration = true;
  });

  return hasStartIteration && !hasExitIteration;
}

export default function useIterationHelpers() {
  const { blockData, id } = useBlockContext();
  const { state } = useToolbuilderContext();
  const {
    currentState: { availableFields, blocks }
  } = state;

  const parentBlocks =
    availableFields[id]
      ?.map((name) => blocks.find((b) => b.data.label === name))
      .filter((b) => !!b) || [];

  const isIterationBlock = [
    BlockType.IterationStart,
    BlockType.IterationExit
  ].includes(blockData.type as BlockType);

  const isIterationChild =
    !isIterationBlock && checkIsIterationChild(parentBlocks);

  return {
    isIterationChild,
    isIterationBlock
  };
}
