import React from "react";
import FullWidthCard from "../../../../utilities/components/cards/FullWidthCard";
import RemixWrapperIconWithTheme from "../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { toolflowPrimaryColor } from "../../../../globalTheme/muiUtils/appTheme";
import AddSnippetButtonOnKnowledgePage from "./AddSnippetButtonOnKnowledgePage";
import SnippetTable from "./SnippetTable";
import { SnippetIcon } from "../../../../globalTheme/icons/icons";

const SnippetsCard = () => {
  return (
    <FullWidthCard
      className="p-24px"
      title="Snippets"
      action={<AddSnippetButtonOnKnowledgePage />}
      cardHeaderClassName="p-0px"
      headerIcon={
        <RemixWrapperIconWithTheme
          style={{ marginTop: "5px", marginRight: "5px" }}
          Icon={SnippetIcon}
          providedColor={toolflowPrimaryColor}
          size={20}
        />
      }
    >
      <SnippetTable />
    </FullWidthCard>
  );
};

export default SnippetsCard;
