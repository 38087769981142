import { useMemo } from "react";
import {
  DEFAULT_TAG,
  OpenAiTextModel,
  type TUniversalAgent,
  VisibilityTypes
} from "@toolflow/shared";
import useGetAgentByParams from "./useGetAgentByParams";
import {
  AGENT_DESCRIPTION_FIELD_LABEL,
  AGENT_INSTRUCTIONS_FIELD_LABEL,
  AGENT_MODEL_FIELD_LABEL,
  AGENT_NAME_FIELD_LABEL,
  AGENT_TAG_FIELD_LABEL,
  AGENT_TEMPERATURE_FIELD_LABEL,
  AGENT_TOOLS_FIELD_LABEL,
  AGENT_VISIBILITY_FIELD_LABEL
} from "../constants/fields";
import { DEFAULT_AGENT_NAME } from "../constants/defaults";

const getDefaultAgentFormValues = (agent?: TUniversalAgent) => {
  return {
    config: {
      [AGENT_INSTRUCTIONS_FIELD_LABEL]:
        agent?.config[AGENT_INSTRUCTIONS_FIELD_LABEL] || {},
      [AGENT_MODEL_FIELD_LABEL]:
        agent?.config[AGENT_MODEL_FIELD_LABEL] || OpenAiTextModel.GPT4o,
      [AGENT_TEMPERATURE_FIELD_LABEL]:
        agent?.config[AGENT_TEMPERATURE_FIELD_LABEL] || 50,
      [AGENT_TOOLS_FIELD_LABEL]: agent?.config[AGENT_TOOLS_FIELD_LABEL] || []
    },
    [AGENT_TAG_FIELD_LABEL]: agent?.[AGENT_TAG_FIELD_LABEL] || DEFAULT_TAG,
    [AGENT_NAME_FIELD_LABEL]:
      agent?.[AGENT_NAME_FIELD_LABEL] || DEFAULT_AGENT_NAME,
    [AGENT_DESCRIPTION_FIELD_LABEL]:
      agent?.[AGENT_DESCRIPTION_FIELD_LABEL] || "",
    [AGENT_VISIBILITY_FIELD_LABEL]:
      agent?.[AGENT_VISIBILITY_FIELD_LABEL] || VisibilityTypes.PUBLIC
  };
};

const useDefaultAgentFormValues = () => {
  const { data } = useGetAgentByParams();
  return useMemo(
    () => getDefaultAgentFormValues(data?.result.agent),
    [data?.result.agent]
  );
};

export default useDefaultAgentFormValues;
