import { createEntityAdapter } from "@reduxjs/toolkit";
import type { ExternalToolFE } from "@toolflow/shared";

// this normalizes data and has a number of built in functions
// ids: [], entities: {} for faster lookup
// we can reuse adapters as long as they have the same shape

// https://redux-toolkit.js.org/api/createEntityAdapter
export const externalToolAdapter = createEntityAdapter({
  selectId: (tool: ExternalToolFE) => tool._id
});

export const externalToolsInitialState = externalToolAdapter.getInitialState();
