import React from "react";
import SettingsMenuItemWithClose from "../../../../../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import { useFormContext } from "react-hook-form";
import type { TWorkflowBuilderForm } from "@toolflow/shared";
import useIsDirty from "../../../../../../utilities/hooks/useIsDirty";
import { ResetIcon } from "../../../../../../globalTheme/icons/icons";

function ResetWorkflowMenuItem() {
  const { reset } = useFormContext<TWorkflowBuilderForm>();
  const { isDirty } = useIsDirty();

  const handleReset = () => {
    reset();
  };

  return (
    <SettingsMenuItemWithClose
      action={handleReset}
      text="Reset workflow"
      hotkey="Meta+Shift+R"
      icon={<ResetIcon fontSize="small" />}
      hide={!isDirty}
    />
  );
}

export default ResetWorkflowMenuItem;
