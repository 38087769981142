import { Box, Divider } from "@mui/material";
import {
  validateAndParseJson,
  BlockNode,
  type StructuredBlockData,
  type StructuredBlockProps
} from "@toolflow/shared";
import { Fragment, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { TEXTFIELD } from "../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestToolRun from "../../common/TestToolRun";
import useTest from "../hooks/useTest";
import StructuredOutput from "./structuredOutput/StructuredOutput";
import EmptyTestBlock from "../components/EmptyTestBlock";
import StructuredBlockTestInput from "./structuredOutput/components/StructuredBlockTestInput";
import useFields from "../hooks/useFields";

export default function TestStructured({
  data,
  id
}: Omit<StructuredBlockProps, "selected">) {
  const { settings } = data;
  const isFilled = settings.input;
  const { userInput, fields } = useFields([]);
  const { action, text, endIcon, showUpgrade, loading, output } =
    useTest<StructuredBlockData>({
      data,
      id,
      customField: {
        name: settings.input,
        id: uuidv4(),
        type: TEXTFIELD
      },
      blockType: BlockNode.StructuredBlockNode,
      fields,
      userInput
    });

  const outputData = useMemo(
    () => validateAndParseJson(output as string),
    [loading]
  );

  return (
    <DrawerBottom
      mainContent={
        <>
          {isFilled ? (
            <Fragment>
              <StructuredBlockTestInput settings={settings} />
              <Divider />
              {!!outputData && (
                <Box sx={{ marginInline: 2, marginTop: 2, paddingBottom: 2 }}>
                  <StructuredOutput outputData={outputData} />
                </Box>
              )}
            </Fragment>
          ) : (
            <EmptyTestBlock
              text="Ready to test your extraction"
              subText="Configure your extraction fields in the left panel then test to see the results here."
            />
          )}
        </>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          disabled={!isFilled}
          loading={loading}
        />
      }
    />
  );
}
