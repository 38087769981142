import type {
  DeleteSnippetResponse,
  GetSnippetResponseFE,
  Snippet,
  SnippetResponseFE,
  SnippetResponse,
  UpdateSnippet
} from "@toolflow/shared";
import { authenticatedApi } from "../authenticatedAPI";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import { getErrorMessage } from "../errors/getErrorMessage";
import { snippetAdapter } from "../../stores/adapters/snippetAdapter";
import { type EntityState } from "@reduxjs/toolkit";
import { SNIPPET_TAG_TYPE } from "../cacheTagConstants";
import { useAuthStore } from "../../features/auth/hooks/useAuthStore";

export const snippetApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    saveSnippet: builder.mutation<SnippetResponse, { body: UpdateSnippet }>({
      query: ({ body }) => ({
        url: "snippet",
        method: "POST",
        body
      }),
      invalidatesTags: [SNIPPET_TAG_TYPE],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Saved snippet"));
        } catch (e) {
          dispatch(setErrorMessage(getErrorMessage(e, "Error saving snippet")));
        }
      }
    }),
    copySnippet: builder.mutation<SnippetResponse, string>({
      query: (snippetId: string) => ({
        url: `snippet/${snippetId}/copy`,
        method: "POST"
      }),
      invalidatesTags: [SNIPPET_TAG_TYPE],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Copied snippet"));
        } catch (e) {
          dispatch(
            setErrorMessage(getErrorMessage(e, "Error copying snippet"))
          );
        }
      }
    }),
    getSnippet: builder.query<GetSnippetResponseFE, string>({
      query: (snippetId) => ({
        url: `snippet/${snippetId}`,
        method: "GET"
      }),
      providesTags: (result, error, snippetId) => [
        { type: SNIPPET_TAG_TYPE, id: snippetId }
      ]
    }),
    updateSnippet: builder.mutation<
      SnippetResponse,
      { body: UpdateSnippet; snippetId: string }
    >({
      query: ({ body, snippetId }) => ({
        url: `snippet/${snippetId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: [SNIPPET_TAG_TYPE],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Saved snippet"));
        } catch (e) {
          dispatch(setErrorMessage(getErrorMessage(e, "Error saving snippet")));
        }
      }
    }),
    deleteSnippet: builder.mutation<DeleteSnippetResponse, string>({
      query: (id: string) => ({
        url: `snippet/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: [SNIPPET_TAG_TYPE],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(setSuccessMessage("Deleted snippet"));
        } catch (err) {
          // `onError` side-effect
          dispatch(
            setErrorMessage(getErrorMessage(err, "Error deleting snippet"))
          );
        }
      }
    }),
    getSnippets: builder.query<EntityState<Snippet, string>, void>({
      query: () => ({
        url: "snippet",
        method: "GET"
      }),
      providesTags: [SNIPPET_TAG_TYPE],
      transformResponse: (response: SnippetResponseFE) => {
        const snippetsToUpsert = response.success
          ? response.result.snippets
          : [];
        return snippetAdapter.upsertMany(
          snippetAdapter.getInitialState(),
          snippetsToUpsert
        );
      }
    })
  })
});

export const snippetApiEndpoints = snippetApi.endpoints;

export const {
  useUpdateSnippetMutation,
  useSaveSnippetMutation,
  useDeleteSnippetMutation,
  useGetSnippetsQuery: useGetSnippetsQueryInner,
  useGetSnippetQuery,
  useCopySnippetMutation
} = snippetApi;

export const useGetSnippetsQuery = (skip?: boolean) => {
  const { isLoading, isAuthenticated } = useAuthStore();
  const { data, ...rest } = useGetSnippetsQueryInner(undefined, {
    skip: isLoading || !isAuthenticated || skip
  });
  return { data, ...rest };
};
