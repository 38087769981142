import type {
  TMarketplaceGroupTool,
  TMarketplaceGroupWorkflow,
  TMarketplaceGroupUseCase
} from "@toolflow/shared";

export const MARKETPLACE_GROUP_TOOL: TMarketplaceGroupTool = "Tools";
export const MARKETPLACE_GROUP_WORKFLOW: TMarketplaceGroupWorkflow =
  "Workflows";
export const MARKETPLACE_GROUP_USE_CASE: TMarketplaceGroupUseCase = "Use Cases";
export const AUTOCOMPLETE_GROUPS = [
  MARKETPLACE_GROUP_TOOL,
  MARKETPLACE_GROUP_WORKFLOW,
  MARKETPLACE_GROUP_USE_CASE
];
