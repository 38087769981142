import type { SelectToolsFieldProps } from "@toolflow/shared";
import InputLabelWrapper from "../helpers/InputLabelWrapper";
import { SearchToolsDialog } from "../../../../../../../tools/components/searchToolsDialog/SearchToolsDialog";
import { SearchIcon } from "../../../../../../../../globalTheme/icons/icons";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import { Button } from "@mui/material";
import useFieldsByTypeHelper from "../../useFieldsByTypeHelper";
import ToolListComponent from "./inputs/ToolListComponent";

const SelectToolsField = ({ settings, field }: SelectToolsFieldProps) => {
  const { label, subtitle } = field;
  const { fieldKey } = field.config;
  const { updateField } = useFieldsByTypeContext();

  const { getFieldValue } = useFieldsByTypeHelper({ settings });

  const currentValue = getFieldValue(fieldKey, []);
  const updateToolId = (value: string) => {
    if (!currentValue.includes(value)) {
      updateField([...currentValue, value], fieldKey);
    }
  };

  const updateFieldValues = (valuesList: string[]) => {
    updateField(valuesList, fieldKey);
  };

  return (
    <InputLabelWrapper label={label} subtitle={subtitle} className="m-b-16px">
      <div>
        <SearchToolsDialog
          onClickEntity={updateToolId}
          title="Find tool"
          disabledEntities={{ workflows: true }}
          button={
            <Button onClick={() => {}} startIcon={<SearchIcon size={14} />}>
              Find tool
            </Button>
          }
        />
      </div>
      <ToolListComponent
        toolIds={currentValue}
        handleChange={updateFieldValues}
      />
    </InputLabelWrapper>
  );
};

export default SelectToolsField;
