import type { LeanFileDocument, UploadFileResponseFE } from "@toolflow/shared";
import useToolflowAPI from "../../../../ToolflowAPI/useToolflowAPI";
import { useCallback, useState } from "react";
import { useAuthStore } from "../../../auth/hooks/useAuthStore";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../../stores/actions";
import { authenticatedApi } from "../../../../ToolflowAPI/authenticatedAPI";
import type { AxiosProgressEvent } from "axios";
import { FileUploadError, useGetFileErrorMessage } from "../errors/fileErrors";
import useValidateFile from "./useValidateFile";
import handleAudioDuration from "../functions/handleAudioDuration";

const useSelectFile = ({
  setCurrentFile
}: {
  setCurrentFile: (file: LeanFileDocument | null) => void;
}) => {
  const { uploadFile } = useToolflowAPI();

  const [tmpFile, setTmpFile] = useState<{ name: string; size: number } | null>(
    null
  );
  const [progress, setProgress] = useState(0);
  const [compressFileCTA, setCompressFileCTA] = useState(false);
  const getFileErrorMessage = useGetFileErrorMessage();

  const validateFile = useValidateFile();

  const reset = () => {
    setTmpFile(null);
    setProgress(0);
  };

  const reduxDispatch = useDispatch();
  const handleError = (error: FileUploadError) => {
    reset();
    reduxDispatch(setErrorMessage(getFileErrorMessage(error)));
    if (error === FileUploadError.FILE_SIZE) {
      setCompressFileCTA(true);
    }
  };

  const initializeFileUpload = (files: File[]) => {
    const file = files[0];
    setCompressFileCTA(false);
    setTmpFile({ name: file.name, size: file.size });
    setProgress(0);
    reduxDispatch(setErrorMessage(""));
    return file;
  };

  const { cancelUpload } = useToolflowAPI();
  const abortUpload = () => {
    cancelUpload();
    setCurrentFile(null);
    reset();
  };

  const handleFileSelect = useCallback(
    async (files: File[] | null) => {
      if (files && files.length > 0) {
        const file = initializeFileUpload(files);
        const errorMessage = validateFile(file);
        if (errorMessage) {
          handleError(errorMessage);
          return;
        }

        const audioDuration = await handleAudioDuration(file);

        const parseResponse = (resp: UploadFileResponseFE) => {
          reset();
          reduxDispatch(authenticatedApi.util.invalidateTags(["File"]));
          setCurrentFile(resp.savedFile);
        };
        const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
          try {
            if (progressEvent.total) {
              setProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            }
          } catch (error) {
            console.error("Error in onUploadProgress:", error);
            handleError(FileUploadError.GENERIC);
          }
        };
        console.log("uploading file", audioDuration);
        await uploadFile(
          { file, duration: audioDuration },
          parseResponse,
          reset,
          onUploadProgress
        );
      }
    },
    [setCurrentFile]
  );
  return {
    tmpFile,
    progress,
    abortUpload,
    handleFileSelect,
    compressFileCTA
  };
};

const useFileUpload = ({
  setCurrentFile,
  disabled
}: {
  setCurrentFile: (file: LeanFileDocument | null) => void;
  disabled?: boolean;
}) => {
  const { tmpFile, progress, handleFileSelect, compressFileCTA, abortUpload } =
    useSelectFile({ setCurrentFile });
  const { isAuthenticated } = useAuthStore();

  return {
    tmpFile,
    progress,
    handleFileSelect,
    abortUpload,
    compressFileCTA,
    computedDisabled: !isAuthenticated || disabled
  };
};

export default useFileUpload;
