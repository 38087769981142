import type { JSONContent } from "@tiptap/core";
import { getHtmlFromText, type StringAsset } from "@toolflow/shared";
import useChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useChipsToUse";
import useInputChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useInputChipsToUse";
import { useAssetContext } from "../../../../../../../../utilities/contexts/AssetContext";
import { useAuthStore } from "../../../../../../../auth/hooks/useAuthStore";
import useGetWorkspaceAssetIndexById from "../../../../../assets/useGetWorkspaceAssetIndexById";
import { useWorkspaceAssetsFieldArrayContext } from "../../../../../contexts/WorkspaceAssetsContext";
import useGetVisibleAssetValue from "../../../../../hooks/useGetVisibleAssetValue";

const useAssetTextField = () => {
  const { isAuthenticated } = useAuthStore();
  const chipsToUse = useInputChipsToUse();
  const chipsWithSnippets = useChipsToUse(chipsToUse);

  const { asset } = useAssetContext<StringAsset>();
  const { update } = useWorkspaceAssetsFieldArrayContext();
  const value = useGetVisibleAssetValue();

  const getInputIndex = useGetWorkspaceAssetIndexById();
  const onUpdate = (s: string | JSONContent) => {
    const newInput = {
      ...asset,
      value: s
    };
    update(getInputIndex(asset.id), newInput);
  };

  const initialContent = getHtmlFromText(value);

  return {
    id: asset.id,
    onUpdate,
    disableEditing: !isAuthenticated,
    initialContent,
    chipsToUse: chipsWithSnippets
  };
};

export default useAssetTextField;
