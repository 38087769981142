import { LeanDocument } from "mongoose";
import { TAvailableTiers } from ".";
import { AnthropicModel, PerplexityModel } from "../LLMModels";
import { IntegrationPriceSchemaId } from "../schemaTypes";
import {
  DeprecatedOpenAITextModels,
  OpenAITextModels,
  TCrawlerActors,
  TDeepgramModels
} from "../types";

// what is the nice-name of the model called? e.g. "openai-gpt-3.5"
export type TIntegrationReferenceName =
  | OpenAITextModels
  | DeprecatedOpenAITextModels
  | TSpeechToTextReferenceName
  | TIntegrationReferenceNameDallE
  | TApifyScraperReferenceName
  | AnthropicModel
  | TApifySerpReferenceName
  | PerplexityModel;
// model - quality - size
export type TIntegrationReferenceNameDallE =
  | "dall-e-2"
  | "dall-e-3-standard-1024x1024"
  | "dall-e-3-standard-1792x1024"
  | "dall-e-3-standard-1024x1792"
  | "dall-e-3-hd-1024x1024"
  | "dall-e-3-hd-1792x1024"
  | "dall-e-3-hd-1024x1792";

export type TWhisperDeepgramReferenceName = TDeepgramModels;
export type TApifyScraperReferenceName = TCrawlerActors;
export type TApifySerpReferenceName = "serp";

export type TSpeechToTextReferenceName = TWhisperDeepgramReferenceName;

// mostly for organization purposes, e.g. "OpenAI"
export enum ServiceReferenceName {
  Deepgram = "deepgram",
  Scraper = "apify",
  OpenAI = "openAI",
  Anthropic = "anthropic",
  AzureOpenAI = "azureOpenAI",
  PerplexityAI = "perplexityAI"
}

export type TMaxCreditCostBlock = "block";
export type TMaxCreditCostImage = "image";
export type TMaxCreditCostMinute = "minute";
export type TMaxCreditCostMillion = "millionTokens";

export type TMaxCreditCostUnit =
  | TMaxCreditCostBlock
  | TMaxCreditCostImage
  | TMaxCreditCostMinute
  | TMaxCreditCostMillion;

export type TMaxCreditCostUnitWithLabelAndValue = {
  label: TMaxCreditCostUnit;
  value: number;
};
export type IntegrationPrice = {
  _id: string;
  integrationReferenceName: TIntegrationReferenceName;
  serviceReferenceName: ServiceReferenceName;
  maxCreditCost: number;
  maxCreditCostUnit: TMaxCreditCostUnit;
  maxCreditCostUnitWithLabelAndValue?: TMaxCreditCostUnitWithLabelAndValue;
  inputTokenCost?: number;
  outputTokenCost?: number;
  maxTokenOutput?: number;
  availableToTiers: TAvailableTiers[];
  createdAt: number;
  updatedAt: number;
};

// IntegrationPriceDocuments are documents that maintain the price of a given integration
export type IntegrationPriceDocument = Omit<IntegrationPrice, "_id"> &
  Document & {
    _id: IntegrationPriceSchemaId;
  };

export type TIntegrationPriceLeanDocument =
  LeanDocument<IntegrationPriceDocument>;
