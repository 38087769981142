import {
  BlockType,
  type ConstantBlockData,
  type DeepgramBlockData,
  type IValidateProps,
  type LogicBlockData,
  type LogicItem,
  type ScraperBlockData,
  type ToolWithinToolBlockData,
  UtilBlockType,
  type ValidatorFunction
} from "@toolflow/shared";
import { convertToBlockMessageType } from "./convertToBlockMessageType";

export const blockValidators: Record<string, ValidatorFunction<$TSFixMe>> = {
  [BlockType.Deepgram]: ({ data }: IValidateProps<DeepgramBlockData>) => {
    if (data.settings.file === "") {
      return convertToBlockMessageType(
        `No mapped file to transcribe.`,
        "error"
      );
    }
  },
  [BlockType.Scraper]: ({ data }: IValidateProps<ScraperBlockData>) => {
    if (data.settings.urlFieldInputKey === "") {
      return convertToBlockMessageType(`No mapped url to scrape`, "error");
    }
  },
  [UtilBlockType.ToolWithinTool]: ({
    data
  }: IValidateProps<ToolWithinToolBlockData>) => {
    if (
      data.inputMap &&
      Object.values(data.inputMap).some((value) => value === "")
    ) {
      return convertToBlockMessageType(`Not all inputs are mapped.`, "warning");
    }
  },
  [UtilBlockType.Constant]: ({ data }: IValidateProps<ConstantBlockData>) => {
    if (!data.constant) {
      return convertToBlockMessageType(
        `A constant must have a value.`,
        "error"
      );
    }
  },
  [UtilBlockType.Logic]: ({
    data,
    edges,
    id
  }: IValidateProps<LogicBlockData>) => {
    if (
      data?.logicArray.filter((item: LogicItem) => item.input !== "").length ===
      0
    ) {
      return convertToBlockMessageType(
        `Logic datas can't be empty. Add a filter.`,
        "error"
      );
    }
    if (!edges.some((e) => e.source === id)) {
      return convertToBlockMessageType(
        `Logic datas must connect their output to another data. Delete this data if it is unused.`,
        "error"
      );
    }
    if (
      data?.logicArray.some((logic: LogicItem) => logic.parameterValue === "")
    ) {
      return convertToBlockMessageType(
        `Logic filters need to be fully filled out.`,
        "error"
      );
    }
  }
};
