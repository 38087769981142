import React, { useState, FC, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import type {
  DeepgramBlockSettings,
  CustomToolInputField,
  CustomToolInputFieldTypes
} from "@toolflow/shared";
import useUpdateField, { useBatchUpdateField } from "../../useUpdateField";
import type { SelectChangeEvent } from "@mui/material/Select";
import { Checkbox, FormControlLabel } from "@mui/material";
import AddInputDialog from "../../addInput/AddInputDialog";
import { deepgramLanguages, deepgramModels } from "./deepgramUtils";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";
import LabelWithToolTip from "./LabelWithToolTip";
import GetKeywordsFromUser from "./GetKeywordsFromUser";
import GetReplaceWordsFromUser from "./GetReplaceWordsFromUser";
import DeepgramFileUploadSettings from "./DeepgramFileUploadSettings";
import SubSettingsLayout from "./SubSettingsLayout";
import {
  DOUBLE_TEXTFIELD,
  FILE_UPLOAD,
  TAGS_INPUT
} from "../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import AddKeywordsSettingsToolbuilder from "./AddKeywordsSettingsToolbuilder";
import ReplaceWordsFormToolbuilder from "./ReplaceWordsFormToolbuilder";

interface IDeepgramSettingsProps {
  id: string;
  settings: DeepgramBlockSettings;
}
const DeepgramSettings: FC<IDeepgramSettingsProps> = (props) => {
  const { id, settings } = props;
  const updateField = useUpdateField(id);
  const batchUpdateFields = useBatchUpdateField(id);
  const [openInputModal, setOpenInputModal] = useState(false);
  const [initialFieldType, setInitialFieldType] =
    useState<CustomToolInputFieldTypes>();

  const handleChangeInitialFieldType = (field: CustomToolInputFieldTypes) => {
    setInitialFieldType(field);
  };
  const {
    language,
    diarize,
    model,
    addTimestamps,
    addSummary,
    convertMeasurementsValuesToAbbreviations,
    keywords,
    wordsToReplace,
    isFileDictation,
    timeStampByEntity
  } = settings;
  useSyncCurrentAndPrevSettings(settings);

  const updateStateValue =
    (kString: keyof DeepgramBlockSettings) =>
    (event: SelectChangeEvent<string>) => {
      updateField(event.target.value, `settings.${kString}`);
    };

  const updateBooleanStateValue =
    (kString: keyof DeepgramBlockSettings) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateField(event.target.checked, `settings.${kString}`);
    };
  const updateFromAdd = (field: CustomToolInputField) => {
    if (field.type === FILE_UPLOAD) {
      updateField(field.name, "settings.file");
    }
    if (field.type === TAGS_INPUT) {
      updateField(field.name, "settings.userKeywordsFieldKey");
    }
    if (field.type === DOUBLE_TEXTFIELD) {
      updateField(field.name, "settings.userWordsToReplaceFieldKey");
    }
  };

  const openInput = () => {
    setOpenInputModal(true);
  };

  const modelSpecificLanguages = deepgramLanguages[model];
  const isWhisperSelected = model === "whisper-medium";

  useEffect(() => {
    if (isWhisperSelected) {
      batchUpdateFields([
        { updateValue: [], dataProperty: "settings.keywords" },
        { updateValue: {}, dataProperty: "settings.wordsToReplace" },
        { updateValue: "", dataProperty: "settings.userKeywordsFieldKey" },
        { updateValue: "", dataProperty: "settings.userWordsToReplaceFieldKey" }
      ]);
    }
  }, [isWhisperSelected]);

  return (
    <>
      <div className="p-h-16px">
        <DeepgramFileUploadSettings
          id={id}
          openInput={openInput}
          settings={settings}
          updateFieldKey="settings.file"
          handleChangeInitialFieldType={handleChangeInitialFieldType}
        />
      </div>
      <AccordionWrapper
        title="Configuration"
        className="m-b-16px"
        startsExpanded
        elevation={0}
      >
        {!isWhisperSelected && (
          <SubSettingsLayout
            title="Keywords"
            subheader="Keywords boost specialized terminology and are a quick way to provide the model with new vocabulary that it has not previously encountered."
          >
            {"keywords" in settings && (
              <AddKeywordsSettingsToolbuilder id={id} tags={keywords} />
            )}
            <GetKeywordsFromUser
              id={id}
              openInput={openInput}
              settings={settings}
              updateFieldKey="settings.userKeywordsFieldKey"
              handleChangeInitialFieldType={handleChangeInitialFieldType}
            />
          </SubSettingsLayout>
        )}
        {!isWhisperSelected && (
          <SubSettingsLayout
            title="Find and replace"
            subheader="Find and replace searches for terms in submitted audio and replaces them. These words are case sensitive. Find and replace does not replace speaker names."
          >
            {"wordsToReplace" in settings && (
              <ReplaceWordsFormToolbuilder
                id={id}
                wordsToReplace={wordsToReplace}
              />
            )}

            <GetReplaceWordsFromUser
              id={id}
              openInput={openInput}
              settings={settings}
              updateFieldKey="settings.userWordsToReplaceFieldKey"
              handleChangeInitialFieldType={handleChangeInitialFieldType}
            />
          </SubSettingsLayout>
        )}

        <SubSettingsLayout title="Model Settings">
          <Grid container spacing={2}>
            {"language" in settings && (
              <Grid xs={6}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="language-label">Audio Language</InputLabel>
                  <Select
                    labelId="language-label"
                    id="language"
                    value={language}
                    fullWidth
                    label="Audio Language"
                    onChange={updateStateValue("language")}
                    className="capitalize nowheel nodrag nopan"
                  >
                    {modelSpecificLanguages.map(
                      (option: { value: string; label: string }) => (
                        <MenuItem
                          value={option.value}
                          key={option.value}
                          className="capitalize"
                        >
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {"model" in settings && (
              <Grid xs={6}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="model-label">Model</InputLabel>
                  <Select
                    labelId="model-label"
                    id="model"
                    value={model}
                    fullWidth
                    label="Model"
                    onChange={updateStateValue("model")}
                    className="capitalize nowheel nodrag nopan"
                  >
                    {deepgramModels.map(
                      (option: { value: string; label: string }) => (
                        <MenuItem
                          value={option.value}
                          key={option.value}
                          className="capitalize"
                        >
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {"diarize" in settings && (
              <Grid xs={6}>
                <FormControl margin="none" fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={diarize}
                        onChange={updateBooleanStateValue("diarize")}
                        className="capitalize nowheel nodrag nopan"
                        value={diarize}
                      />
                    }
                    label="Diarize"
                  />
                </FormControl>
              </Grid>
            )}
            {"addTimestamps" in settings && (
              <Grid xs={6}>
                <FormControl margin="none" fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addTimestamps}
                        onChange={updateBooleanStateValue("addTimestamps")}
                        className="capitalize nowheel nodrag nopan"
                        value={addTimestamps}
                      />
                    }
                    label="Add timestamps"
                  />
                </FormControl>
              </Grid>
            )}
            {addTimestamps && "timeStampByEntity" in settings && (
              <Grid xs={12}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="Add Timestamps By">
                    Add Timestamps By
                  </InputLabel>
                  <Select
                    labelId="Add Timestamps By"
                    id="model"
                    value={timeStampByEntity}
                    fullWidth
                    label="Add Timestamps By"
                    onChange={updateStateValue("timeStampByEntity")}
                    className="capitalize nowheel nodrag nopan"
                  >
                    <MenuItem
                      value={"paragraph"}
                      key={"Paragraph"}
                      className="capitalize"
                    >
                      Paragraph
                    </MenuItem>
                    <MenuItem
                      value={"sentence"}
                      key={"Sentence"}
                      className="capitalize"
                    >
                      Sentence
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {"addSummary" in settings && (
              <Grid xs={6}>
                <FormControl margin="none" fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addSummary}
                        onChange={updateBooleanStateValue("addSummary")}
                        className="capitalize nowheel nodrag nopan"
                        value={addSummary}
                      />
                    }
                    label="Summarize"
                  />
                </FormControl>
              </Grid>
            )}
            {"isFileDictation" in settings && (
              <Grid xs={6}>
                <FormControl margin="none" fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFileDictation}
                        onChange={updateBooleanStateValue("isFileDictation")}
                        className="capitalize nowheel nodrag nopan"
                        value={isFileDictation}
                      />
                    }
                    label={
                      <LabelWithToolTip
                        label="Dictation"
                        toolTipContent="Convert spoken dictation commands to their corresponding punctuation marks (e.g. comma -> ,)."
                      />
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {"convertMeasurementsValuesToAbbreviations" in settings && (
              <Grid xs={12}>
                <FormControl margin="none" fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={convertMeasurementsValuesToAbbreviations}
                        onChange={updateBooleanStateValue(
                          "convertMeasurementsValuesToAbbreviations"
                        )}
                        className="capitalize nowheel nodrag nopan"
                        value={convertMeasurementsValuesToAbbreviations}
                      />
                    }
                    label={
                      <LabelWithToolTip
                        label="Convert measurements"
                        toolTipContent="Convert measurements to abbreviations (e.g. milligram(s) to mg)."
                      />
                    }
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </SubSettingsLayout>
        <AddInputDialog
          openModal={openInputModal}
          setOpenModal={setOpenInputModal}
          callback={updateFromAdd}
          hideTypeDropdown
          initialType={initialFieldType}
        />
      </AccordionWrapper>
    </>
  );
};

export default DeepgramSettings;
