import React, { useEffect, useMemo } from "react";
import { TEntityTab, marketplaceCategoryTabs } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../stores/store";
import type { TEntityFilterByCategoriesFE } from "@toolflow/shared";
import { setCategory } from "../../slice/searchToolsSlice";
import { ALL_CATEGORY_TAB } from "../../utils/constants";
import { isSelectedTabDisabled, getCategoryIndex } from "../functions/utils";

const useSwitchCategoryWhenDisabled = () => {
  const selectedTab = useSelector((state: RootState) => state.searchTools.tab);
  const reduxCategory = useSelector(
    (state: RootState) => state.searchTools.category
  );

  const dispatch = useDispatch();

  const handleChangeTabIndex = () => {
    dispatch(setCategory(ALL_CATEGORY_TAB));
  };

  const isTabDisabled = useMemo(() => {
    return isSelectedTabDisabled(reduxCategory, selectedTab);
  }, [selectedTab, reduxCategory]);

  useEffect(() => {
    if (isTabDisabled) {
      handleChangeTabIndex();
    }
  }, [isTabDisabled]);
};

const useFilteredTabs = () => {
  const selectedTab = useSelector((state: RootState) => state.searchTools.tab);
  return useMemo(
    () =>
      marketplaceCategoryTabs.filter(
        (tab) => !tab.disabledTabs?.includes(selectedTab)
      ),
    [selectedTab]
  );
};

const useSelectedTabIndex = (filteredTabs: TEntityTab[]) => {
  const reduxCategory = useSelector(
    (state: RootState) => state.searchTools.category
  );
  return getCategoryIndex(filteredTabs, reduxCategory);
};

const useHandleChangeTabIndex = (filteredTabs: TEntityTab[]) => {
  const dispatch = useDispatch();
  const handleChangeTabIndex = (
    event: React.SyntheticEvent,
    newIndex: number
  ) => {
    const newCategory = filteredTabs[newIndex]
      ?.value as TEntityFilterByCategoriesFE;
    if (newCategory) {
      dispatch(setCategory(newCategory));
    }
  };
  return handleChangeTabIndex;
};

const useEntityCategory = () => {
  const selectedTab = useSelector((state: RootState) => state.searchTools.tab);
  const filteredTabs = useFilteredTabs();
  const selectedTabIndex = useSelectedTabIndex(filteredTabs);
  const handleChangeTabIndex = useHandleChangeTabIndex(filteredTabs);
  useSwitchCategoryWhenDisabled();

  return {
    selectedTabIndex,
    handleChangeTabIndex,
    marketplaceCategoryTabs: filteredTabs,
    selectedTab
  };
};

export default useEntityCategory;
