import {
  BlockNode,
  type SerpBlockData,
  type SerpBlockProps
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import { TEXTFIELD } from "../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import TestToolRun from "../../common/TestToolRun";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestSerpFields from "./TestSerpFields";
import TestBlockOutput from "../components/TestBlockOutput";
import useTest from "../hooks/useTest";
import useFields from "../hooks/useFields";

function TestSerp({ data, id }: Omit<SerpBlockProps, "selected">) {
  const { userInput, setUserInput, fields } = useFields([]);
  const { action, text, endIcon, showUpgrade, output, label, loading } =
    useTest<SerpBlockData>({
      data,
      id,
      customField: {
        name: data.settings.userQueriesFieldKey,
        id: uuidv4(),
        type: TEXTFIELD
      },
      blockType: BlockNode.SerpBlockNode,
      fields: [],
      userInput
    });

  return (
    <DrawerBottom
      mainContent={
        <>
          <TestSerpFields fields={fields} uI={userInput} setUI={setUserInput} />
          <TestBlockOutput output={output} label={label} />
        </>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
}

export default TestSerp;
