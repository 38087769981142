import { Button } from "@mui/material";
import React, { FC } from "react";
import useToolflowNavigate from "../../navigation/hooks/useToolflowNavigate";
import useResetWorkstation from "../../pages/workstation/hooks/useResetWorkstation";
import { AddIcon } from "../../../globalTheme/icons/icons";

interface ISidebarActionButtonProps {
  className?: string;
}
const SidebarActionButton: FC<ISidebarActionButtonProps> = ({ className }) => {
  // get functuionaly from New Workstation button and navigate also
  // return null;
  const navigate = useToolflowNavigate();
  const resetWorkstation = useResetWorkstation();

  const newTool = (e: React.MouseEvent) => {
    resetWorkstation();
    navigate("/", e);
  };
  return (
    <div className={className}>
      <Button
        variant="action"
        fullWidth
        startIcon={<AddIcon size={20} />}
        onClick={newTool}
      >
        New session
      </Button>
    </div>
  );
};

export default SidebarActionButton;
