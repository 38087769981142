import useToolInputFieldsAndAvailableFields from "../../useToolInputFieldsAndAvailableFields";

export default function useIterationAvailableFields(id: string) {
  const availableFields = useToolInputFieldsAndAvailableFields(id);

  return availableFields
    .filter((field) => {
      return (
        (field.config?.valueType === "array" || field.type === "tagsInput") &&
        !field.config?.nestedOutputKey?.includes("[]")
      );
    })
    .map((field) => {
      return {
        label: field.config?.label || field.name,
        value: `{{${field.config?.label || field.name}}}`
      };
    });
}
