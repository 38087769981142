import { ThemeProvider } from "@mui/material";
import React from "react";
import SendMessageOnDirtyMonitor from "../monitors/SendMessageOnDirtyMonitor";
import WorkspaceFormProvider from "../../state/components/WorkspaceFormProvider";
import workspaceTheme from "../../theme/workspaceTheme";
import WorkspaceHeader from "../workspaceHeader/WorkspaceHeader";

import WorkspaceMainContent from "./WorkspaceMainContent";

function NewWorkspacePage() {
  return (
    <ThemeProvider theme={workspaceTheme}>
      <WorkspaceFormProvider>
        <SendMessageOnDirtyMonitor>
          <WorkspaceHeader />
          <WorkspaceMainContent />
        </SendMessageOnDirtyMonitor>
      </WorkspaceFormProvider>
    </ThemeProvider>
  );
}

export default NewWorkspacePage;
