import React from "react";
import Logo from "../../../../globalTheme/icons/images/Logo.png";
import ToolflowLogoBase from "./ToolflowLogoBase";

function ToolflowLogoName({
  className = "",
  height = 24
}: {
  height?: number;
  className?: string;
}) {
  return <ToolflowLogoBase className={className} icon={Logo} height={height} />;
}

export default ToolflowLogoName;
