import React, { useState } from "react";
import {
  EntityType,
  type TMarketplaceUniversalEntity,
  type TMarketplaceUniversalEntityType,
  type MarketplaceAutocompleteGroups,
  type TDisabledEntities,
  type TSelectAutocomplete
} from "@toolflow/shared";
import {
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from "@mui/material";
import { CloseIcon } from "../../../../globalTheme/icons/icons";
import { SearchToolsContextComponent } from "../searchToolsContainer/context/SearchToolsContext";
import EntityCard from "../../../entities/components/entityCard/EntityCard";
import UniversalAutocompletePositionWrapper from "../../../../utilities/components/autocomplete/universalAutocomplete/UniversalAutocompletePositionWrapper";
import UniversalAutocomplete from "../../../../utilities/components/autocomplete/universalAutocomplete/UniversalAutocomplete";
import {
  MARKETPLACE_GROUP_TOOL,
  MARKETPLACE_GROUP_WORKFLOW
} from "../../../../utilities/components/autocomplete/constants/autocompleteConstants";
import { toolflowAppBackground } from "../../../../globalTheme/muiUtils/appTheme";
import { useAuthStore } from "../../../auth/hooks/useAuthStore";
import ToolTabs from "../searchToolsContainer/ToolTabs";

export const useSearchToolsDialog = (
  onClick: (id: string, type: TMarketplaceUniversalEntityType) => void
) => {
  const [open, setOpen] = useState(false);
  const { isLoading } = useAuthStore();

  const onClickEntityWithClose = (
    id: string,
    type: TMarketplaceUniversalEntityType
  ) => {
    onClick(id, type);
    setOpen(false);
  };

  const EntityElementWithOnClick = React.useCallback(
    ({ entity }: { entity: TMarketplaceUniversalEntity }) => (
      <EntityCard
        entity={entity}
        onClick={() => onClickEntityWithClose(entity.id, entity.type)}
        disableActions
      />
    ),
    [onClickEntityWithClose]
  );

  return {
    open,
    setOpen,
    EntityElementWithOnClick,
    onClickEntityWithClose,
    disabled: isLoading
  };
};

export const SearchToolsDialogUniversalAutocomplete = ({
  onClickEntity,
  disabledEntities
}: {
  onClickEntity: (id: string, type: TMarketplaceUniversalEntityType) => void;
  disabledEntities?: TDisabledEntities;
}) => {
  const handleSelectEntity: TSelectAutocomplete = (id, type) => {
    const entityMapper: Partial<
      Record<MarketplaceAutocompleteGroups, EntityType>
    > = {
      [MARKETPLACE_GROUP_TOOL]: EntityType.TOOL,
      [MARKETPLACE_GROUP_WORKFLOW]: EntityType.WORKFLOW
    };
    const mappedType = entityMapper[type];
    if (mappedType) {
      onClickEntity(id, mappedType);
    }
  };

  return (
    <UniversalAutocomplete
      className="max-w-530px"
      autoFocus
      handleSelectEntity={handleSelectEntity}
      disabledFields={{
        useCases: true,
        workflows: disabledEntities?.workflows,
        tools: disabledEntities?.tools
      }}
    />
  );
};

export const SearchToolsDialog = ({
  onClickEntity,
  button,
  title,
  disabledEntities
}: {
  onClickEntity: (id: string, type: TMarketplaceUniversalEntityType) => void;
  disabledEntities?: TDisabledEntities;
  button: React.ReactElement<{
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
  }>;
  title: string;
}) => {
  const {
    open,
    EntityElementWithOnClick,
    setOpen,
    onClickEntityWithClose,
    disabled
  } = useSearchToolsDialog(onClickEntity);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        onClose={() => setOpen(false)}
        maxWidth="xl"
        PaperProps={{
          sx: { backgroundColor: toolflowAppBackground }
        }}
      >
        <div className="pos-relative">
          <IconButton
            onClick={() => setOpen(false)}
            className="pos-absolute"
            sx={{ top: 24, right: 24 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Container maxWidth="lg" className="m-t-32px">
              <div className="flex align-i-center w-100-percent flex-wrap">
                <Typography variant="h5">{title}</Typography>
                <UniversalAutocompletePositionWrapper>
                  <SearchToolsDialogUniversalAutocomplete
                    onClickEntity={onClickEntityWithClose}
                    disabledEntities={disabledEntities}
                  />
                </UniversalAutocompletePositionWrapper>
              </div>
              <SearchToolsContextComponent
                EntityElement={EntityElementWithOnClick}
                entityType={EntityType.TOOL}
              >
                <ToolTabs
                  className="m-t-16px"
                  disabledEntities={disabledEntities}
                />
              </SearchToolsContextComponent>
            </Container>
          </DialogContent>
        </div>
      </Dialog>
      {React.cloneElement(button as React.ReactElement, {
        onClick: (e: React.MouseEvent) => {
          setOpen(true);
          const originalOnClick = (button as React.ReactElement).props.onClick;
          if (originalOnClick) {
            originalOnClick(e);
          }
        },
        disabled
      })}
    </>
  );
};
