import { useMemo } from "react";
import { useAllMarketplaceEntities } from "../../entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";

const useToolIsFeatured = (objectId: string) => {
  const allMarketplaceEntities = useAllMarketplaceEntities();
  return useMemo(() => {
    return !!allMarketplaceEntities.find((entity) => entity.id === objectId);
  }, [allMarketplaceEntities, objectId]);
};

export default useToolIsFeatured;
