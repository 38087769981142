import React from "react";
import ToolflowLogoName from "../../../../../../globalTheme/icons/logos/toolflowLogo/ToolflowLogoName";
import ResetThreadButton from "./ResetThreadButton";
import styles from "./chatHeader.module.css";

import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

function ChatHeader() {
  return (
    <div
      className={cx(
        "flex justify-space-between p-16px align-i-center",
        styles.header
      )}
    >
      <ToolflowLogoName height={20} />
      <ResetThreadButton />
    </div>
  );
}

export default ChatHeader;
