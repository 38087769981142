import type { TDeepgramModels } from "@toolflow/shared";
import {
  baseModelLanguages,
  enhancedModelLanguages,
  nova2Languages,
  novaLanguages,
  whisperMediumLanguages
} from "./dgSupportedLanguages";

export const deepgramModels: { label: string; value: TDeepgramModels }[] = [
  {
    label: "Nova 2",
    value: "nova-2"
  },
  {
    label: "Nova",
    value: "nova"
  },
  {
    label: "Base",
    value: "base"
  },
  {
    label: "Enhanced",
    value: "enhanced"
  },
  {
    label: "Whisper",
    value: "whisper-medium"
  }
];

export const deepgramLanguages: {
  [key: string]: { label: string; value: string }[];
} = {
  "nova-2": nova2Languages,
  nova: novaLanguages,
  base: baseModelLanguages,
  enhanced: enhancedModelLanguages,
  "whisper-medium": whisperMediumLanguages
};
