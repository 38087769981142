export function getStringsBetweenBraces(str: string) {
  const results: number[][] = [];
  let curIdx = 0;
  for (let i = 0; i < str.length - 1; i++) {
    if (str[i] === "{" && str[i + 1] === "{") {
      results[curIdx] = [i + 2];
    } else if (str[i] === "}" && str[i + 1] === "}") {
      results[curIdx].push(i);
      curIdx++;
    }
  }
  return results.map((indices) => str.slice(indices[0], indices[1]));
}
