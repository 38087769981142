import type {
  PopulatedToolVersionResponseFE,
  RowUserInputDictType
} from "@toolflow/shared";
import type { MRT_Row } from "material-react-table";
import useGetValidatedUserInputFromRow from "./useGetValidatedUserInputFromRow";
import useCheckFileStatus from "../../../../../../../../../tools/hooks/useCheckToolStatus";
import useUpdateSheetDataQueue from "./useUpdateSheetDataQueue";
import useConvertStringToTipTap from "../../../../../../../../../../utilities/formatters/strings/tiptap/hooks/useConvertStringToJSON";
import updateSingleValueInRow from "../functions/updateSingleValueInRow";
import { getIsOutOfCreditsError } from "../../../../../../../../../../ToolflowAPI/errors/getIsOutOfCreditsError";
import { TColumnMap } from "../../sheetTypes";
import useSetRowLoading from "./useSetRowLoading";
import { useSheetContext } from "../../SheetContext";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../../../../../../../../stores/actions";
import { useRunToolMutation } from "../../../../../../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import { textFieldTypeArray } from "../../../../../../../../../tools/components/editorToolCard/inputs/helpers/textFieldTypes";

const useRunToolFromSheet = ({ columnMap }: { columnMap: TColumnMap }) => {
  const { selectedTool } = useSheetContext();
  const setRowLoading = useSetRowLoading();
  const [runTool] = useRunToolMutation();
  const { queueUpdate } = useUpdateSheetDataQueue();
  const convertTextToTipTap = useConvertStringToTipTap();
  const { startCheckingFile } = useCheckFileStatus({
    onWebSocketToolVersionResponse: (
      response: PopulatedToolVersionResponseFE,
      componentId?: string
    ) => {
      // we need to set this as a function to avoid stale data issues when running multiple tools at once
      queueUpdate((currentSheetData) => {
        const index = currentSheetData.findIndex(
          (r) => r.componentId === componentId
        );

        if (index !== -1) {
          const row = currentSheetData[index];
          let newRow: RowUserInputDictType = { ...row, rowLoading: false };

          // Iterate through responseDict once
          Object.entries(response.responseDict).forEach(([key, value]) => {
            // Check if the current key matches any column name
            const column = columnMap.get(key);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (column && column.meta!.type === "output") {
              let newValue = value;
              // Check if the column's type is in textFieldTypeArray and process the value
              // We need to make it JSON so that we can use MuiTipTap correctly
              // We also convert text back from JSON to string when downloading
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              if (textFieldTypeArray.includes(column.meta!.value.type)) {
                newValue = convertTextToTipTap(value as string);
              }
              // Update the newRow with the new value
              newRow = updateSingleValueInRow(newRow, key, newValue);
            }
          });

          return currentSheetData.map((item, idx) =>
            idx === index ? newRow : item
          );
        }
        return currentSheetData;
      });
    }
  });

  const getValidatedUserInputFromRow = useGetValidatedUserInputFromRow();
  const reduxDispatch = useDispatch();

  const runToolFromSheet = async (row: MRT_Row<RowUserInputDictType>) => {
    if (!selectedTool) {
      return;
    }
    const { newUserInput, error } = getValidatedUserInputFromRow(row.original);
    if (!!error) {
      reduxDispatch(setErrorMessage(error));
      setRowLoading(row, false);
      return;
    }
    const componentId = row.id;

    const throwError = (e: unknown) => {
      if (getIsOutOfCreditsError(e)) {
        reduxDispatch(
          setErrorMessage(
            "You've run out of credits, please upgrade your account. Navigate to Settings > Billing to upgrade."
          )
        );
      }
      setRowLoading(row, false);
    };

    try {
      await runTool({
        toolData: { userInput: newUserInput, componentId },
        toolId: selectedTool._id
      })
        .unwrap()
        .then((resp) => {
          // Start checking the file status periodically
          startCheckingFile(resp.toolVersionResponse._id, row.id);
        });
    } catch (e) {
      throwError(e);
    }
  };
  return runToolFromSheet;
};

export default useRunToolFromSheet;
