import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import type { UpdateSnippet } from "@toolflow/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  SNIPPET_NAME_FIELD_LABEL,
  SNIPPET_VALUE_FIELD_LABEL
} from "./snippetFields";
import useResetDefaultValuesAndRerender from "./hooks/useResetDefaultValuesAndRerender";

export const SNIPPET_NAME_REQUIRED_ERROR = "Snippet name is required";
export const SNIPPET_VALUE_REQUIRED_ERROR = "Snippet value is required";

const schema = z.object({
  [SNIPPET_NAME_FIELD_LABEL]: z
    .string()
    .min(1, { message: SNIPPET_NAME_REQUIRED_ERROR }),
  [SNIPPET_VALUE_FIELD_LABEL]: z
    .string()
    .min(1, { message: SNIPPET_VALUE_REQUIRED_ERROR })
});

const DEFAULT_SNIPPET_FORM_VALUES: UpdateSnippet = {
  name: "",
  snippetValue: ""
};

/**
 * Formats initial values for the snippet form
 * @param initialName - Initial name of the snippet
 * @param initialValue - Initial value of the snippet
 * @param useDefaultValues - Flag to use default values instead of provided initials
 * @returns Formatted initial values for the form
 */
const formatInitialValues = (
  initialName: string,
  initialValue: string,
  useDefaultValues = false
): UpdateSnippet => {
  return useDefaultValues
    ? DEFAULT_SNIPPET_FORM_VALUES
    : { name: initialName, snippetValue: initialValue };
};

interface SnippetFormProviderProps {
  children: React.ReactNode;
  initialName?: string;
  initialValue?: string;
  initialStateIsDirty?: boolean;
}

/**
 * SnippetFormProvider component
 * Provides form context and handling for snippet creation/editing
 */
const SnippetFormProvider: React.FC<SnippetFormProviderProps> = ({
  children,
  initialName = "",
  initialValue = "",
  initialStateIsDirty = false
}) => {
  const methods = useForm<UpdateSnippet>({
    resolver: zodResolver(schema),
    defaultValues: formatInitialValues(
      initialName,
      initialValue,
      initialStateIsDirty
    ),
    mode: "onChange"
  });

  // Reset form values when initial values change
  // if the default values are used, then the form values will be dirty
  useResetDefaultValuesAndRerender(
    formatInitialValues(initialName, initialValue),
    methods
  );

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default SnippetFormProvider;
