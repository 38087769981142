import React from "react";
import { useEntityContext } from "../../context/EntityContext";
import useCurrentUserIsEntityOwner from "../../hooks/useCurrentUserIsEntityOwner";
import DuplicateToolButton from "../../../tools/components/DuplicateToolButton";
import { EditEntityButton } from "./EditEntityButton";
import { EntityType } from "@toolflow/shared";

export const EditOrDuplicateSplitter = ({
  className
}: {
  className?: string;
}) => {
  const { entity } = useEntityContext();
  const currentUserIdOwner = useCurrentUserIsEntityOwner();
  if (currentUserIdOwner) {
    return <EditEntityButton className={className} />;
  }
  if (entity.type === EntityType.TOOL) {
    return <DuplicateToolButton id={entity.id} className={className} />;
  }
  return null;
};
