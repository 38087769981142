import type { TMarketplaceEntity } from "@toolflow/shared";

export function getUniqueEntity(entities: TMarketplaceEntity[]) {
  const uniqueTools = entities.reduce((acc, entity) => {
    acc.set(entity.id, entity);
    return acc;
  }, new Map<string, TMarketplaceEntity>());

  return Array.from(uniqueTools.values());
}
