import { parseBooleanEnv } from "./parseBooleanEnv";

export const getFEUrl = () => {
  const isPullRequest = parseBooleanEnv(
    process.env.REACT_APP_PREVIEW_IS_PULL_REQUEST
  );
  if (isPullRequest) {
    return process.env.REACT_APP_PREVIEW_RENDER_FE_URL;
  }
  return process.env.REACT_APP_FE_URL;
};
