import {
  type BlockMessageType,
  type CustomEdge,
  type BlockDataTypesWithDefault
} from "@toolflow/shared";
import { getLatestBlockVersionByType } from "./blocks/constants/helpers/getLatestBlockVersion";
import { convertToBlockMessageType } from "./blocks/constants/convertToBlockMessageType";
import { blockValidators } from "./blocks/constants/blockValidators";

function setBlockMessage({
  data,
  edges,
  id
}: {
  data?: BlockDataTypesWithDefault;
  edges: CustomEdge[];
  id?: string;
}): BlockMessageType | undefined {
  if (!data) return undefined;
  let blockMessageType: BlockMessageType | undefined = undefined;

  if (data.version !== getLatestBlockVersionByType(data.type)?.data.version) {
    blockMessageType = convertToBlockMessageType(
      `There is a newer version of this block. Drag the latest version from the sidebar to upgrade.`,
      "warning"
    );
  }

  const validator = blockValidators[data.type];
  if (validator) {
    return validator({ data, edges, id });
  }

  // If no errors were found, return null
  return blockMessageType;
}

export default setBlockMessage;
