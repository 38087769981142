import React from "react";
import UniversalAutocomplete from "./UniversalAutocomplete";
import type { TSelectAutocomplete } from "@toolflow/shared";
import { useNavigate } from "react-router-dom";
import { TOOLS_PAGE_ROUTE_PATH } from "../../../../features/navigation/helpers/routePaths";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import {
  MARKETPLACE_GROUP_TOOL,
  MARKETPLACE_GROUP_USE_CASE,
  MARKETPLACE_GROUP_WORKFLOW
} from "../constants/autocompleteConstants";

const UniversalAutocompleteContainer = () => {
  const navigate = useNavigate();
  const currentPath = useSelector(
    (state: RootState) => state.navigation.currentPath
  );

  const handleOpenMarketplaceEntity: TSelectAutocomplete = (id, type) => {
    const isCurrentPageMarketplace = currentPath === TOOLS_PAGE_ROUTE_PATH;
    if (type === MARKETPLACE_GROUP_TOOL) {
      navigate(`/tool/${id}`);
    } else if (type === MARKETPLACE_GROUP_WORKFLOW) {
      navigate(`/workflow/${id}`);
    } else if (
      type === MARKETPLACE_GROUP_USE_CASE &&
      !isCurrentPageMarketplace
    ) {
      navigate(TOOLS_PAGE_ROUTE_PATH);
    }
  };
  return (
    <UniversalAutocomplete
      handleSelectEntity={handleOpenMarketplaceEntity}
      className="max-w-530px"
    />
  );
};

export default UniversalAutocompleteContainer;
