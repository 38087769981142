import { Divider } from "@mui/material";
import List from "@mui/material/List";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import sidebarTheme from "../../../globalTheme/sidebarTheme";
import {
  homeMatcher,
  knowledgeMatcher,
  findToolsPageMatcher,
  addAgentMatcher,
  workflowBuilderMatcher,
  toolBuilderMatcher,
  agentsMatcher
} from "../../navigation/functions/matchers";
import {
  HOME_NESTED_MAIN_ROUTE_PATH,
  KNOWLEDGE_ROUTE_PATH,
  TOOLS_PAGE_ROUTE_PATH,
  AGENT_BUILDER_BASE_ROUTE_PATH,
  WORKFLOWBUILDER_BASE_ROUTE_PATH,
  TOOLBUILDER_BASE_ROUTE_PATH,
  AGENTS_ROUTE_PATH
} from "../../navigation/helpers/routePaths";
import ToolflowLogoName from "../../../globalTheme/icons/logos/toolflowLogo/ToolflowLogoName";
import SidebarActionButton from "./SidebarActionButton";
import SidebarButtonContainer from "./SidebarButtonContainer";
import { SidebarCredits } from "./SidebarCredits";
import SidebarIcon from "./SidebarIcon";
import StyledSidebarDrawer from "./StyledSidebarDrawer";
import TermsOfServiceSidebar from "./TermsOfServiceSidebar";
import ToggleSidebarButton from "./ToggleSidebarButton";
import styles from "./sidebar.module.css";
import {
  BuilderFillIcon,
  BuilderIcon,
  HomeFillIcon,
  HomeIcon,
  KnowledgeFillIcon,
  KnowledgeIcon,
  ToolIcon,
  ToolFillIcon,
  AddAgentIcon,
  AddAgentFillIcon,
  WorkflowIcon,
  AgentsFillIcon,
  AgentsIcon
} from "../../../globalTheme/icons/icons";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { setSidebarClose } from "./sidebarSlice";
import { useDispatch } from "react-redux";
import { EFeatureFlags } from "@toolflow/shared";

interface SidebarProps {
  isMediumScreen: boolean;
  open: boolean;
  isAuthenticated: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  isMediumScreen,
  open,
  isAuthenticated
}) => {
  const workflowBuilderEnabled = useFeatureFlagEnabled(
    EFeatureFlags.WORKFLOW_BUILDER
  );
  const dispatch = useDispatch();
  const closeSidebar = () => {
    dispatch(setSidebarClose());
  };
  const addAgentEnabled = useFeatureFlagEnabled(EFeatureFlags.AGENT_BUILDER);

  const topButtons = [
    {
      route: HOME_NESTED_MAIN_ROUTE_PATH,
      icon: <SidebarIcon Icon={HomeIcon} />,
      selectedIcon: <SidebarIcon Icon={HomeFillIcon} />,
      text: "Home",
      authRequired: true,
      matcher: homeMatcher
    },
    {
      route: TOOLS_PAGE_ROUTE_PATH,
      icon: <SidebarIcon Icon={ToolIcon} />,
      selectedIcon: <SidebarIcon Icon={ToolFillIcon} />,
      text: "Tools",
      matcher: findToolsPageMatcher
    },
    {
      route: TOOLBUILDER_BASE_ROUTE_PATH,
      icon: <SidebarIcon Icon={BuilderIcon} />,
      selectedIcon: <SidebarIcon Icon={BuilderFillIcon} />,
      text: "Builder",
      authRequired: true,
      matcher: toolBuilderMatcher,
      callback: closeSidebar
    },
    {
      route: KNOWLEDGE_ROUTE_PATH,
      icon: <SidebarIcon Icon={KnowledgeIcon} />,
      selectedIcon: <SidebarIcon Icon={KnowledgeFillIcon} />,
      text: "Knowledge",
      authRequired: true,
      matcher: knowledgeMatcher
    },
    {
      route: AGENTS_ROUTE_PATH,
      icon: <SidebarIcon Icon={AgentsIcon} />,
      selectedIcon: <SidebarIcon Icon={AgentsFillIcon} />,
      text: "Agents",
      authRequired: true,
      matcher: agentsMatcher,
      featureFlagEnabled: addAgentEnabled
    },
    {
      route: AGENT_BUILDER_BASE_ROUTE_PATH,
      icon: <SidebarIcon Icon={AddAgentIcon} />,
      selectedIcon: <SidebarIcon Icon={AddAgentFillIcon} />,
      text: "Agent Builder",
      authRequired: true,
      matcher: addAgentMatcher,
      featureFlagEnabled: addAgentEnabled
    },
    {
      route: WORKFLOWBUILDER_BASE_ROUTE_PATH,
      icon: <SidebarIcon Icon={WorkflowIcon} />,
      selectedIcon: <SidebarIcon Icon={WorkflowIcon} />,
      text: "Workflow Builder",
      matcher: workflowBuilderMatcher,
      authRequired: true,
      featureFlagEnabled: workflowBuilderEnabled
    }
  ];

  return (
    <ThemeProvider theme={sidebarTheme}>
      <StyledSidebarDrawer
        variant="permanent"
        open={open}
        PaperProps={{ square: true, className: "scrollable-content" }}
        className={`sidebar  flex-column justify-space-between flex ${
          isMediumScreen ? "h-100-percent" : "h-100vh"
        }`}
      >
        <div className="flex align-i-center p-t-24px p-b-24px justify-space-between p-l-32px p-r-16px">
          <ToolflowLogoName />
          {open && <ToggleSidebarButton />}
        </div>
        <Divider className={styles.divider} />
        <div className="h-100-percent flex flex-column justify-space-between p-h-16px">
          <div>
            <SidebarActionButton className="m-v-12px p-h-12px" />
            <List>
              {topButtons.map((button, index) => {
                if (button.authRequired && !isAuthenticated) {
                  return null;
                }
                if (
                  "featureFlagEnabled" in button &&
                  !button.featureFlagEnabled
                ) {
                  return null;
                }
                return (
                  <SidebarButtonContainer
                    key={index}
                    route={button.route}
                    icon={button.icon}
                    selectedIcon={button.selectedIcon}
                    text={button.text}
                    matcher={button.matcher}
                    callback={button.callback}
                  />
                );
              })}
            </List>
          </div>
          <div>
            <List>
              <SidebarCredits />
            </List>
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className="flex align-i-center p-v-16px justify-space-between p-l-32px p-r-16px">
          <TermsOfServiceSidebar />
        </div>
      </StyledSidebarDrawer>
    </ThemeProvider>
  );
};

export default Sidebar;
