import React from "react";
import { BillingPortalCard } from "./BillingPortalCard";
import {
  HYPERLINE_CUSTOMER_ORGANIZATION,
  HYPERLINE_CUSTOMER_USER
} from "../../../../billing/helpers/billingConstants";
import { Typography } from "@mui/material";
import { ContactSalesCard } from "./ContactSalesCard";

const PricingSection = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <Typography variant="h7" className="m-b-8px">
        Subscription
      </Typography>
      <BillingPortalCard customerType={HYPERLINE_CUSTOMER_ORGANIZATION} />
      <BillingPortalCard customerType={HYPERLINE_CUSTOMER_USER} />
      {/* <SubscriptionSection /> */}
      <ContactSalesCard />
    </div>
  );
};

export default PricingSection;
