import React from "react";
import ChatInputPaper from "../chat/chatbox/ChatInputPaper/ChatInputPaper";
import Greetings from "../../../../../utilities/components/Greetings";
import styles from "./getStarted.module.css";
import { GetStartedHeader } from "./GetStartedHeader";
import useFindToolButton from "../chat/chatbox/ChatInputPaper/hooks/useFindToolButton";
import { SearchToolsDialog } from "../../../../tools/components/searchToolsDialog/SearchToolsDialog";
import { Button } from "@mui/material";
import { SearchIcon } from "../../../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../../../utilities/functions/parsedClassNames";
import { SuggestedEntityCards } from "./SuggestedEntityCards";
import { SessionHistoryButton } from "./SessionHistoryButton";

export const cx = parsedClassNames.bind(styles);

function GetStarted() {
  const { selectTool } = useFindToolButton();
  return (
    <div className="flex align-i-center justify-center w-100-percent h-100-percent">
      <div
        className={cx(
          "flex flex-column justify-space-between w-100-percent h-100-percent",
          styles["get-started-container"]
        )}
      >
        <div>
          <div className="flex justify-center w-100-percent">
            <SessionHistoryButton className="m-b-16px" />
          </div>
          <Greetings
            align="center"
            className={styles.greetings}
            variant="h32Medium"
            component="div"
          />
          <GetStartedHeader text="Start with a tool" />
          <SuggestedEntityCards className="m-b-24px" />
          <SearchToolsDialog
            onClickEntity={selectTool}
            title="Find tool"
            button={
              <Button
                variant="contained"
                size="medium"
                className="m-b-16px"
                startIcon={<SearchIcon size={20} />}
              >
                Find tool
              </Button>
            }
          />
        </div>
        <div>
          <GetStartedHeader text="Or ask a question" />
          <ChatInputPaper />
        </div>
      </div>
    </div>
  );
}

export default GetStarted;
