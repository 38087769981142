import React from "react";
import AssignUseCasesNoSaveBase from "../../../../tools/components/editorToolCard/toolCategorization/AssignUseCasesNoSaveBase";
import useGetAgentUseCases from "../state/hooks/useGetAgentUseCases";
import useSetAgentUseCases from "../state/hooks/useSetAgentUseCases";

const AssignUseCasesFromAgentBuilder = () => {
  const initialSelectedTags = useGetAgentUseCases();
  const setAgentUseCases = useSetAgentUseCases();

  return (
    <AssignUseCasesNoSaveBase
      handleUpdate={setAgentUseCases}
      initialSelectedTags={initialSelectedTags}
    />
  );
};

export default AssignUseCasesFromAgentBuilder;
