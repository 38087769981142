import { BlockSettingsBase, BlockSettingsWithInputType } from "../blocks";
import { UpdateSType } from "../types";
import { TAddChipConfig } from "./addChipTypes";

export enum FieldType {
  SelectInputV1 = "select-input-v1",
  SelectInputV2 = "select-input-v2",
  CustomSelectInput = "custom-select-input",
  JsonSchemaInput = "json-schema-input",
  PromptInput = "prompt-input",
  PromptInputV2 = "prompt-input-v2",
  Slider = "slider",
  NewSelectInput = "new-select-input",
  SelectTools = "select-tools"
}

export type UpdateField = (
  updateValue: UpdateSType,
  dataProperty: string,
  nestedArrayIndex?: number,
  nestedArrayProp?: string
) => void;

export type SelectOption =
  | string
  | { label: string; value: string; subText?: string };

export interface FieldsByTypePropsBase {
  settings: BlockSettingsBase;
  id: string;
  updateField: UpdateField;
}

export interface FieldsByTypeProps extends FieldsByTypePropsBase {
  configs: FieldConfig;
}

export interface PromptInputWrapperProps extends FieldsByTypePropsBase {
  field: PromptInputFieldType;
}
export interface TextInputContainerProps extends FieldsByTypePropsBase {
  field: PromptInputV2FieldType;
}
export interface SelectToolsFieldProps extends FieldsByTypePropsBase {
  field: SelectToolsFieldType;
}
export interface SelectFieldV1Props extends FieldsByTypePropsBase {
  field: SelectFieldV1FieldType;
}

export interface SelectFieldV2Props extends FieldsByTypePropsBase {
  field: SelectFieldV2FieldType;
}

export interface CustomSelectInputWrapperProps extends FieldsByTypePropsBase {
  field: CustomSelectInputFieldType;
  settings: BlockSettingsWithInputType;
}

export interface JsonSchemaInputWrapperProps extends FieldsByTypePropsBase {
  field: JsonSchemaInputFieldType;
}

export interface SliderFieldProps extends FieldsByTypePropsBase {
  field: SliderFieldType;
}

export interface NewSelectInputWrapperProps extends FieldsByTypePropsBase {
  field: NewSelectInputFieldType;
}

export interface BaseInputFieldProps {
  label: string;
  subtitle?: string;
}

export type NewSelectInputFieldConfig = {
  fieldKey: string;
  options: SelectOption[];
  callback?: (value: string) => void;
};

export interface NewSelectInputFieldType extends BaseInputFieldProps {
  type: FieldType.NewSelectInput;
  config: NewSelectInputFieldConfig;
}

export type SelectInputFieldV1Config = {
  fieldKey: string;
  options: string[];
  callback?: (value: string) => void;
};

export type SelectInputFieldV2Config = {
  fieldKey: string;
  options: SelectOption[];
  callback?: (value: string) => void;
};

export interface SelectFieldV1FieldType extends BaseInputFieldProps {
  type: FieldType.SelectInputV1;
  config: SelectInputFieldV1Config;
}

export interface SelectFieldV2FieldType extends BaseInputFieldProps {
  type: FieldType.SelectInputV2;
  config: SelectInputFieldV2Config;
}

export type CustomSelectInputFieldConfig = {
  sourceLabel: string;
  fieldKey: string;
  inputValueType?: "string" | "array";
  userFieldKey?: string;
};

export interface CustomSelectInputFieldType extends BaseInputFieldProps {
  type: FieldType.CustomSelectInput;
  config: CustomSelectInputFieldConfig;
}

export type JsonSchemaInputFieldConfig = {
  subLabel?: string;
};

export interface JsonSchemaInputFieldType extends BaseInputFieldProps {
  type: FieldType.JsonSchemaInput;
  config: JsonSchemaInputFieldConfig;
}

export type PromptInputFieldConfig = {
  prependKey?: string;
  fieldKey: string;
  minRows: number;
  maxRows: number;
};

export interface PromptInputFieldType extends BaseInputFieldProps {
  type: FieldType.PromptInput;
  config: PromptInputFieldConfig;
}

export type PromptInputV2FieldConfig = {
  placeholder?: string;
  autofocus?: boolean;
  fieldKey: string;
  chipsToUse?: TAddChipConfig;
};

export interface PromptInputV2FieldType extends BaseInputFieldProps {
  type: FieldType.PromptInputV2;
  config: PromptInputV2FieldConfig;
}

type PromptInputs = PromptInputV2FieldType | PromptInputFieldType;

export type SliderFieldConfig = {
  fieldKey: string;
  marksMax?: number;
};

export interface SliderFieldType extends BaseInputFieldProps {
  type: FieldType.Slider;
  config: SliderFieldConfig;
}

export type SelectToolsFieldConfig = {
  fieldKey: string;
};

export interface SelectToolsFieldType extends BaseInputFieldProps {
  type: FieldType.SelectTools;
  config: SelectToolsFieldConfig;
}

export type FieldTypeConfigs =
  | CustomSelectInputFieldType
  | SelectToolsFieldType
  | JsonSchemaInputFieldType
  | SelectFieldV1FieldType
  | SelectFieldV2FieldType
  | PromptInputs
  | PromptInputV2FieldType
  | SliderFieldType
  | NewSelectInputFieldType;

export interface FieldConfig {
  fields: FieldTypeConfigs[];
}

export interface IFieldInputProps {
  field: FieldTypeConfigs;
  settings: BlockSettingsBase;
  id: string;
}

export enum DynamicFieldType {
  Dynamic = "dynamic",
  Preset = "preset"
}
