import { type LeanFileDocument } from "@toolflow/shared";
import SelectedFile from "./SelectedFile";
import NoFileSelectedPaper from "./NoSelectedFilePaper";

interface FileUploadButtonInnerProps {
  currentFile: LeanFileDocument | null;
  setCurrentFile: (file: LeanFileDocument | null) => void;
  disabled?: boolean;
}

const FileUploadButtonInner = ({
  setCurrentFile,
  disabled,
  currentFile
}: FileUploadButtonInnerProps) => {
  if (currentFile) {
    return (
      <SelectedFile currentFile={currentFile} setCurrentFile={setCurrentFile} />
    );
  }

  return (
    <NoFileSelectedPaper
      setCurrentFile={setCurrentFile}
      currentFile={currentFile}
      disabled={disabled}
    />
  );
};

export default FileUploadButtonInner;
