import { useMemo } from "react";
import { useGetAllUserWorkflowsAsEntities } from "./useGetAllUserWorkflowsAsEntities";
import { useGetAllUserToolsAsEntities } from "./useGetAllUserToolsAsEntities";

export const useGetAllUserEntities = () => {
  const { entities: workflowEntities, loading: workflowsLoading } =
    useGetAllUserWorkflowsAsEntities();
  const { entities: toolEntities, loading: toolsLoading } =
    useGetAllUserToolsAsEntities();

  return {
    entities: [...workflowEntities, ...toolEntities],
    loading: toolsLoading || workflowsLoading
  };
};

export const useOnlyUserEntities = () => {
  const { entities } = useGetAllUserEntities();
  return useMemo(() => entities, [entities]);
};
