import React, { useState } from "react";
import useHandleChatMessageKeyDown from "./useHandleChatMessageKeyDown";
import useIsMessageLoading from "./useIsMessageLoading";
import type { JSONContent } from "@tiptap/core";
import useHandleChatMessageChange from "./useHandleChatMessageChange";
import AbortChatMessageButton from "../abortChatMessageButton/AbortChatMessageButton";
import SendChatMessageButton from "../runButton/SendChatMessageButton";
import useInputChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useInputChipsToUse";

const useSendThreadTextField = () => {
  const [userTextMessage, setUserTextMessage] = useState<JSONContent>({});
  const handleKeyDown = useHandleChatMessageKeyDown(setUserTextMessage);
  const handleChange = useHandleChatMessageChange(setUserTextMessage);
  const isMessageLoading = useIsMessageLoading();
  const chipsToUse = useInputChipsToUse();

  return {
    userTextMessage,
    handleChange,
    handleKeyDown,
    setUserTextMessage,
    isMessageLoading,
    chipsToUse,
    additionalActions: isMessageLoading ? (
      <AbortChatMessageButton />
    ) : (
      <SendChatMessageButton
        userTextMessage={userTextMessage}
        setUserTextMessage={setUserTextMessage}
      />
    )
  };
};

export default useSendThreadTextField;
