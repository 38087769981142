import { DeepgramModel, type DeepgramBlockSettings } from "@toolflow/shared";

export const deepgramTranscribePromptInitialState: {
  settings: DeepgramBlockSettings;
} = {
  settings: {
    language: "en",
    file: "",
    model: DeepgramModel.Nova2,
    smartFormat: true,
    diarize: true,
    addTimestamps: false,
    addSummary: false,
    convertMeasurementsValuesToAbbreviations: false,
    keywords: [],
    userKeywordsFieldKey: "",
    wordsToReplace: {},
    userWordsToReplaceFieldKey: "",
    isFileDictation: false,
    timeStampByEntity: "paragraph"
  }
};
