import {
  type TUniversalAgent,
  AGENT,
  type TMarketplaceAgentEntity
} from "@toolflow/shared";

function convertAgentFEToMarketplaceEntity(
  agent: TUniversalAgent
): TMarketplaceAgentEntity {
  return {
    id: agent._id.toString(),
    name: agent.name,
    description: agent.description || "",
    userId: agent.userId,
    isFeatured: !!agent.isFeatured,
    isPopular: !!agent.isPopular,
    visibility: agent.visibility,
    userFavorited: false,
    tag: agent.tag,
    updatedAt: agent.updatedAt,
    type: AGENT
  };
}

export default convertAgentFEToMarketplaceEntity;
