import {
  PerplexityModel,
  type PerplexityBlockData,
  type PerplexityBlockLLMSettings,
  type PerplexityBlockSettings
} from "@toolflow/shared";

export const perplexitySettingsInitialState: PerplexityBlockSettings = {
  input: ""
};

export const perplexityLLMInitialState: PerplexityBlockLLMSettings = {
  llmModel: PerplexityModel.SonarHgOnline,
  temperature: 50
};

export const perplexityInitialState: Omit<
  PerplexityBlockData,
  "label" | "type"
> = {
  settings: perplexitySettingsInitialState,
  optimizations: perplexityLLMInitialState
};
