import React, { useState } from "react";
import { EntityType, type VisibilityTypes } from "@toolflow/shared";
import SettingsMenuItem from "../../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import { ShareDialogBase } from "../../../../../utilities/components/sharing/ShareDialog";
import UpdateVisibilitySelect from "../../../../../utilities/components/sharing/updateVisibilitySelect/UpdateVisibilitySelect";
import { UpdateVisibilitySelectBase } from "../../../../../utilities/components/sharing/updateVisibilitySelect/UpdateVisibilitySelectBase";
import { useSettingsDropdownContext } from "../../../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import { useUpdateToolMutation } from "../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import type { SelectChangeEvent } from "@mui/material";
import { ShareIcon } from "../../../../../globalTheme/icons/icons";

function UpdateToolVisibilityMenuItem({
  toolId,
  toolVisibility,
  toolBuilder,
  toolName
}: {
  toolId: string;
  toolVisibility: VisibilityTypes;
  toolBuilder?: boolean;
  toolName: string;
}) {
  const { handleClose } = useSettingsDropdownContext();
  const [updateTool, { isLoading }] = useUpdateToolMutation();
  const handlePrivacy = (e: SelectChangeEvent<VisibilityTypes>) => {
    updateTool({
      body: { visibility: e.target.value as VisibilityTypes },
      toolId
    });
    e.stopPropagation();
    e.preventDefault();
  };

  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const close = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    handleClose(e);
    setOpen(false);
  };

  return (
    <>
      <SettingsMenuItem
        disabled={isLoading}
        action={handleOpen}
        text={"Share"}
        hotkey="Meta+Shift+V"
        icon={<ShareIcon fontSize="small" />}
        hide={!toolId}
      />
      <ShareDialogBase
        id={toolId}
        open={open}
        closeDialog={close}
        loading={isLoading}
        name={toolName}
        type={EntityType.TOOL}
      >
        {toolBuilder ? (
          <UpdateVisibilitySelect
            toolId={toolId}
            formProps={{ fullWidth: true }}
          />
        ) : (
          <UpdateVisibilitySelectBase
            handleSelectChange={handlePrivacy}
            visibility={toolVisibility}
            formProps={{ fullWidth: true }}
          />
        )}
      </ShareDialogBase>
    </>
  );
}

export default UpdateToolVisibilityMenuItem;
