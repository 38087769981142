import React from "react";
import LoginButton from "../../auth/LoginButton";
import AuthenticationSplitter from "../../auth/AuthenticationSplitter";
import ToolsLoadingWrapperContainer from "../../tools/components/searchToolsContainer/SearchToolsLoadingSplitter";
import ToolsPageLoading from "../toolsPage/ToolsPageLoading";
import AgentsLoadingSplitter from "./AgentsLoadingSplitter";

const AgentsPage = () => {
  return (
    <AuthenticationSplitter
      loadingComponent={<ToolsPageLoading />}
      authenticatedComponent={<AgentsLoadingSplitter />}
      externalComponent={
        <ToolsLoadingWrapperContainer
          action={
            <div className="flex align-i-center">
              <LoginButton variant="contained" />
            </div>
          }
        />
      }
    />
  );
};

export default AgentsPage;
