import { useAuth0 } from "@auth0/auth0-react";
import { setToolflowAlternativeUrl } from "../../navigation/hooks/setToolflowAlternativeUrl";
import { getFEUrl } from "../../../utilities/formatters/strings/getFEUrl";

const getRelativePath = (path: string) => {
  const feUrl = getFEUrl();
  if (window.toolflowAlternativeUrl && feUrl) {
    return path.replace(new RegExp(`^${feUrl}`), "");
  }
  return path;
};

export const useLoginBackToPage = () => {
  const { loginWithRedirect } = useAuth0();
  return {
    loginBackToPage: () => {
      const returnTo = getRelativePath(
        window.toolflowAlternativeUrl || window.location.pathname
      );
      setToolflowAlternativeUrl(undefined);
      loginWithRedirect({
        appState: {
          returnTo
        }
      });
    }
  };
};
