import { useMemo } from "react";
import { useAllMarketplaceEntities } from "../../entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";

const useToolIsPopular = (objectId: string) => {
  const allMarketplaceEntities = useAllMarketplaceEntities();

  return useMemo(() => {
    return !!allMarketplaceEntities.find(
      (entity) => entity.id === objectId && entity.isPopular
    );
  }, [allMarketplaceEntities, objectId]);
};

export default useToolIsPopular;
