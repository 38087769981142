import { TextField, Typography } from "@mui/material";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../../globalTheme/muiUtils/appTheme";

function TestPromptPreviewTab({
  filledOutPrompt
}: {
  filledOutPrompt: string;
}) {
  return (
    <>
      <Typography variant="body2" color="textSecondary">
        Complete prompt sent to AI, with all instructions and inputs.
      </Typography>
      <TextField
        margin="normal"
        value={filledOutPrompt}
        disabled
        fullWidth
        multiline
        maxRows={MAX_ROWS_LARGE_TEXTFIELD}
        helperText={`${filledOutPrompt.length} characters`}
      />
    </>
  );
}

export default TestPromptPreviewTab;
