import { useSelector } from "react-redux";
import { selectAllProfileWorkflows } from "../../../ToolflowAPI/rtkRoutes/selectors/workflowSelectors";
import { useGetProfileWorkflowsQuery } from "../../../ToolflowAPI/rtkRoutes/workflowApi";
import { useGetCurrentUserProfileId } from "../../user/hooks/useGetCurrentUserProfileId";

export const useGetCurrentUserWorkflows = () => {
  const profileId = useGetCurrentUserProfileId();
  const { isFetching, isLoading } = useGetProfileWorkflowsQuery(profileId, {
    skip: !profileId
  }); // we need this to stay subscribed to the query
  const workflows = useSelector(selectAllProfileWorkflows(profileId));
  return {
    workflows,
    loading: isLoading || isFetching
  };
};
