import { Button, Card, CardHeader } from "@mui/material";
import { ALFIE_CALENDLY_URL } from "./helpers/constants";

export function ContactSalesCard() {
  return (
    <Card variant="outlined" className="m-b-16px">
      <CardHeader
        title="Contact Sales"
        subheader="Want to change your plan? For upgrades, downgrades, or anything else, contact us."
        action={
          <Button
            onClick={() => {
              window.open(ALFIE_CALENDLY_URL, "rel=noopener noreferrer");
            }}
            variant="outlined"
          >
            Contact Sales
          </Button>
        }
      />
    </Card>
  );
}
