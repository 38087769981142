import { useBlocker } from "react-router-dom";
import type { TWorkflowBuilderForm } from "@toolflow/shared";
import useFormIsDirty from "../../../../../utilities/hooks/useIsDirty";

const useWorkflowBlocker = () => {
  const { isDirty } = useFormIsDirty<TWorkflowBuilderForm>();

  const blocker = useBlocker(isDirty);

  return blocker;
};

export default useWorkflowBlocker;
