import { ChipNodeComponents } from "./ChipNodeComponents";

// Only include snippets that you want to be in the fields that populate the preview screen on testing
// For instance, snippets are constants, so shouldn't be allowed to be updated
export const chipsWithVariableInputsArray = [
  ChipNodeComponents.ToolbuilderInputComponent,
  ChipNodeComponents.NodeOutputViewerComponent,
  ChipNodeComponents.AssetComponent,
  ChipNodeComponents.ToolbuilderInputComponent
];
