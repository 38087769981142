import OpenAI from "openai";

export type TOpenAIThread = OpenAI.Beta.Thread;
export type TOpenAIThreadMessage = OpenAI.Beta.Threads.Messages.Message;
export type TOpenAIAssistant = OpenAI.Beta.Assistants.Assistant;
export type TOpenAIAssistantCreateParams =
  OpenAI.Beta.Assistants.AssistantCreateParams;
export type TOpenAIAssistantTool = OpenAI.Beta.Assistants.AssistantTool;

export enum TToolflowAgents {
  AGENT_V1 = "Agent v1",
  TOOLFLOW_OPENAI_AGENT = "Toolflow OpenAI Agent",
  TOOLFLOW_OPENAI_AGENT_WITH_FUNCTION_CALLS = "Toolflow OpenAI Agent With Function Calls"
}

export type TToolflowAgentIds =
  | TToolflowAgentV1Id
  | TToolflowOpenAIAgentId
  | TToolflowOpenAIAgentWithFunctionCallsId;

export enum TToolflowAgentV1Id {
  STAGING = "66f36d72743fc8e79e90fc38",
  DEVELOPMENT = "66f2f07adcc105b89b45f41c",
  PRODUCTION = "66fb243287182ea888cd5957"
}

// DEPRECATED
export enum TToolflowOpenAIAgentId {
  STAGING = "staging",
  DEVELOPMENT = "664374e336383fea89cc2b95",
  PRODUCTION = "production"
}

// DEPRECATED
export enum TToolflowOpenAIAgentWithFunctionCallsId {
  STAGING = "6661d6010ffb5735f700a292",
  DEVELOPMENT = "66548097e03c2a716ce2821f",
  PRODUCTION = "666877d1a575b56e4fe1bbdb"
}

export const mainToolflowAgentIds: string[] = Array.from(
  new Set([
    ...Object.values(TToolflowAgentV1Id),
    ...Object.values(TToolflowOpenAIAgentId),
    ...Object.values(TToolflowOpenAIAgentWithFunctionCallsId)
  ])
);

export type TOpenAIAssistantStreamEvent = OpenAI.Beta.AssistantStreamEvent;
export type TAgentMap = Map<TToolflowAgents, TOpenAIAssistantCreateParams>;
export type TAgentToolSearchArguments = {
  name: string;
  about: string;
  userId: string;
};

export type TThreadNormalMessage = "NORMAL_MESSAGE";
export type TThreadSearchToolsResponseMessage = "SEARCH_TOOLS_RESPONSE_MESSAGE";
export type TThreadMessageType =
  | TThreadNormalMessage
  | TThreadSearchToolsResponseMessage;
