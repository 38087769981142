import { useEffect, useRef } from "react";
import { globalEventEmitterFE } from "../../../utilities/eventEmitter/globalEventEmitterFE";
import { ReadyState } from "react-use-websocket";
import type { PopulatedToolVersionResponseFE } from "@toolflow/shared";
import { useWebsocketContext } from "../../../ToolflowAPI/websocket/contexts/WebsocketContext";

interface UseCheckFileStatusProps {
  onWebSocketToolVersionResponse: (
    toolVersionResponse: PopulatedToolVersionResponseFE,
    componentId?: string
  ) => void;
  intervalDuration?: number;
}

const useCheckFileStatus = ({
  onWebSocketToolVersionResponse,
  intervalDuration = 2000
}: UseCheckFileStatusProps) => {
  const { sendMessage, readyState } = useWebsocketContext();
  const intervalIds = useRef<Record<string, NodeJS.Timeout>>({});
  const componentIds = useRef<Set<string>>(new Set());

  useEffect(() => {
    // Define handleWsMessage here to access componentIds
    const handleWsMessage = (emittedString: string) => {
      const emittedMessage = JSON.parse(emittedString);
      if (
        componentIds.current.has(emittedMessage.componentId) &&
        emittedMessage.type === "websocketToolVersionResponse"
      ) {
        if (emittedMessage.close) {
          onWebSocketToolVersionResponse(
            emittedMessage.toolVersionResponse,
            emittedMessage.componentId
          );
          clearInterval(intervalIds.current[emittedMessage.componentId]);
          delete intervalIds.current[emittedMessage.componentId];
          componentIds.current.delete(emittedMessage.componentId);
        }
      }
    };

    globalEventEmitterFE.on("ws_message", handleWsMessage);

    return () => {
      globalEventEmitterFE.off("ws_message", handleWsMessage);
    };
  }, [onWebSocketToolVersionResponse]);

  const startCheckingFile = (responseId: string, componentId: string) => {
    const previousInterval = intervalIds.current[componentId];
    if (previousInterval) {
      clearInterval(previousInterval);
    }

    componentIds.current.add(componentId);

    intervalIds.current[componentId] = setInterval(() => {
      if (readyState === ReadyState.OPEN && responseId && sendMessage) {
        sendMessage(
          JSON.stringify({
            type: "checkToolStatus",
            payload: { componentId, toolVersionResponseId: responseId }
          })
        );
      }
    }, intervalDuration);

    return () => {
      clearInterval(intervalIds.current[componentId]);
      delete intervalIds.current[componentId];
      componentIds.current.delete(componentId);
    };
  };

  return { startCheckingFile };
};

export default useCheckFileStatus;
