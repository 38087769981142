import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { authenticatedApi } from "../../../ToolflowAPI/authenticatedAPI";
import { posthog } from "posthog-js";

// We need this because a user can logout and then immediately login again
// without the cookie being cleared.
const clearAuth0Cookies = () => {
  document.cookie.split(";").forEach((cookie) => {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.toLowerCase().includes("auth0")) {
      const cookieName = trimmedCookie.split("=")[0];
      document.cookie = `${cookieName}=;expires=${new Date(0).toUTCString()};path=/`;
    }
  });
};

export const useHandleLogout = () => {
  const { logout } = useAuth0();
  const dispatchRedux = useDispatch();

  const handleLogout = () => {
    try {
      posthog.reset();
      dispatchRedux(authenticatedApi.util.resetApiState());
      clearAuth0Cookies();
    } catch (e) {
      console.error(e); // Logging the error might be helpful for debugging
    }

    localStorage.setItem("logoutRedirect", window.location.pathname);

    logout({
      logoutParams: { returnTo: `${window.location.origin}/callback` }
    });
  };

  return handleLogout;
};
