import { CardHeader, Typography } from "@mui/material";
import React from "react";
import EditToolButton from "./EditToolButton";
import DuplicateToolButton from "./DuplicateToolButton";
import ToggleFavorite from "../../../utilities/components/toggleFavorite/ToggleFavorite";
import useCurrentUserIsToolOwner from "./editorToolCard/hooks/useCurrentUserIsToolOwner";
import { useToolContext } from "../contexts/ToolContext";
import { EntityType } from "@toolflow/shared";

function ToolInfo({ action }: { action?: React.ReactNode }) {
  const currentUserIsToolOwner = useCurrentUserIsToolOwner();
  const { tool } = useToolContext();
  return (
    <>
      <CardHeader
        title={
          <div className="flex align-i-center">
            {!currentUserIsToolOwner && (
              <ToggleFavorite
                id={tool._id}
                favorited={tool.userFavorited}
                type={EntityType.TOOL}
                iconButtonSize="small"
                size={16}
                creatorId={tool.creator._id}
                inMarketplace={tool.isFeatured}
              />
            )}
            <Typography variant="h6">{tool.name}</Typography>
          </div>
        }
        action={
          <>
            {currentUserIsToolOwner ? (
              <EditToolButton toolId={tool._id} className="m-r-8px" />
            ) : (
              <DuplicateToolButton id={tool._id} className="m-r-8px" />
            )}

            {action}
          </>
        }
      />
      {tool.about && (
        <Typography className="m-h-16px m-b-16px">{tool.about}</Typography>
      )}
    </>
  );
}

export default ToolInfo;
