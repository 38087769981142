import { createEntityAdapter } from "@reduxjs/toolkit";
import type { TUniversalAgent } from "@toolflow/shared";

export const agentAdapter = createEntityAdapter({
  selectId: (agent: TUniversalAgent) => agent._id
});

export const agentAdapterInitialState = agentAdapter.getInitialState();

export const agentEntityAdapter = createEntityAdapter({
  selectId: (agent: TUniversalAgent) => agent._id
});

export const agentEntityAdapterInitialState =
  agentEntityAdapter.getInitialState();
