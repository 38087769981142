import { CustomEdge, TBlock } from "../models";
import { DecimalString } from "../primitives";
import { BlockDataTypes, BlockDataTypesWithDefault } from "./blocks";
import { BlockMessageType } from "./blocksBaseTypes";
import { ElementType } from "react";

export type DraggableItem = Omit<
  TBlock,
  "id" | "position" | "data" | "parentNode"
> & {
  data: BlockDataTypes;
};

export interface IValidateProps<T extends BlockDataTypesWithDefault> {
  data: T;
  edges: CustomEdge[];
  id?: string;
}

export type ValidatorFunction<T extends BlockDataTypesWithDefault> = (
  props: IValidateProps<T>
) => BlockMessageType | undefined;

export type BlockConfig = {
  draggableItem: DraggableItem;
  icon: ElementType;
  blockPaperLabel: string;
  drawerComponent: ElementType;
  expandedComponent?: ElementType;
  validate?: ValidatorFunction<BlockDataTypes>;
};

export type VersionedBlockConfigValue = Omit<BlockConfig, "draggableItem"> & {
  draggableItem: DraggableItem;
};

export type VersionedBlockConfig = {
  [version: string]: VersionedBlockConfigValue;
};

export const DEFAULT_VERSION_VALUE: DecimalString = "1.0";
