import {
  FieldType,
  type FieldConfig,
  type IterationStartBlockProps
} from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useIterationAvailableFields from "./useIterationAvailableFields";
import useUpdateField from "../../useUpdateField";

export default function IterationStartBlockInner({
  data,
  id
}: Omit<IterationStartBlockProps, "selected">) {
  const { label, settings } = data;
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;
  const iterationAvailableFields = useIterationAvailableFields(id);

  const configs: FieldConfig = {
    fields: [
      {
        type: FieldType.SelectInputV2,
        label: "Choose list",
        subtitle:
          "The Start Iteration node begins a loop over a selected list. Choose the list to iterate through from the dropdown. Subsequent connected nodes will process each item in this list until encountering the Exit Iteration node.",
        config: {
          fieldKey: "input",
          options: iterationAvailableFields
        }
      }
    ]
  };

  const updateField = useUpdateField(id);

  return (
    <>
      <FieldsByType
        id={id}
        settings={settings}
        configs={configs}
        updateField={updateField}
      />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );
}
