import React from "react";
import Guidelines from "../../legal/Guidelines";
import Privacy from "../../legal/Privacy";
import TermsOfService from "../../legal/TermsOfService";
import TermsSeperator from "./TermsSeparator";

function TermsOfServiceSidebar() {
  return (
    <div className="flex">
      <TermsOfService typographyClassName="grey" />
      <TermsSeperator />
      <Privacy typographyClassName="grey" />
      <TermsSeperator />
      <Guidelines typographyClassName="grey" />
    </div>
  );
}

export default TermsOfServiceSidebar;
