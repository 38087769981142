import React from "react";
import { useParams } from "react-router-dom";
import ProfilePageContent from "../pages/profile/ProfilePageContent";
import BillingPageContent from "../pages/billing/BillingPageContent";
import SettingsPageContent from "../pages/settings/SettingsPageContent";

type ProfilePageNestedPathsKeys = "main" | "billing" | "settings";
const profilePageNestedPathsDict: Record<
  ProfilePageNestedPathsKeys,
  { content: React.ReactNode; label: string }
> = {
  main: {
    content: <ProfilePageContent />,
    label: "My profile"
  },
  billing: {
    content: <BillingPageContent />,
    label: "Billing"
  },
  settings: {
    content: <SettingsPageContent />,
    label: "Settings"
  }
};
const useGetProfileContent = () => {
  const { profileNestedRoute = "" } = useParams<{
    profileNestedRoute?: string;
  }>();

  const validRoute: ProfilePageNestedPathsKeys =
    profileNestedRoute in profilePageNestedPathsDict
      ? (profileNestedRoute as ProfilePageNestedPathsKeys)
      : "main";
  const content = profilePageNestedPathsDict[validRoute].content;
  const nestedRouteLabel = profilePageNestedPathsDict[validRoute].label;

  return {
    content,
    nestedRouteLabel
  };
};

export default useGetProfileContent;
