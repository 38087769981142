import { useGetAllUserToolsAsEntities } from "../../entities/hooks/useGetAllUserToolsAsEntities";
import { useMarketplaceToolEntities } from "./useMarketplaceToolEntities";
import { getUniqueEntity } from "../../entities/functions/getUniqueEntity";

const useGetAvailableTools = () => {
  const { entities } = useGetAllUserToolsAsEntities();
  const marketplaceEntities = useMarketplaceToolEntities();
  return getUniqueEntity([...marketplaceEntities, ...entities]);
};

export default useGetAvailableTools;
